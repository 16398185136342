import React, { useEffect, useState, memo } from "react"
import { Link } from "gatsby"
import { StoreContext } from "context/store-context"
import { sendSelectItemGTMEvent } from "utils/gtmEvents"

const RelatedVariant = ({ handle }) => {
  const { client } = React.useContext(StoreContext)
  const [, setLoading] = useState(false)
  const [product, setProduct] = useState(null)
  const [slug, setSlug] = useState("#")
  const isBrowser = typeof window !== "undefined"
  let productPath = ""
  if (isBrowser) {
    productPath = window.location.pathname
  }
  const selectedProductVariant =
    productPath.toLowerCase() === slug.toLowerCase() ||
    productPath.toLowerCase() === `${slug.toLowerCase()}/`

  useEffect(() => {
    let isSubscribed = true
    setLoading(true)

    const fetchProductData = async () => {
      const result = await client.product.fetchByHandle(handle)
      if (isSubscribed) {
        setProduct(result)
        setLoading(false)
      }
    }

    fetchProductData()
    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (product) {
      const slug = `/products/${product.handle}`
      setSlug(slug)
    }
  }, [product])

  return (
    <>
      {product ? (
        <>
          {product.onlineStoreUrl && (
            <Link
              to={slug}
              className="group"
              onClick={() => {
                const selectedVariant = product.variants[0]
                const productGidSplitArray = product.id.split("/")
                const varantGidSplitArray = selectedVariant.id.split("/")

                const parentProductId =
                  productGidSplitArray[[productGidSplitArray.length - 1]]
                const variantId =
                  varantGidSplitArray[[varantGidSplitArray.length - 1]]
                const merchantID = `shopify_CA_${parentProductId}_${variantId}`
                sendSelectItemGTMEvent({
                  item_id: merchantID,
                  item_name: product.title,
                })
              }}
            >
              <div className="w-20 md:w-24 ">
                {product.images.slice(0, 1).map((image) => (
                  <div
                    key={image.src.replace(/.jpg/g, "_92x92.jpg.webp")}
                    className={`relative aspect-square mb-2 h-20 md:h-24 w-20 md:w-24 transition duration-300 ease-in-out rounded-md ${
                      selectedProductVariant
                        ? "border-2 border-idc-blue"
                        : "border"
                    } group-hover:border-idc-blue`}
                  >
                    <img
                      src={image.src.replace(/.jpg/g, "_92x92.jpg.webp")}
                      className={`absolute h-full w-full object-contain ${
                        !product.availableForSale && "opacity-25"
                      }`}
                    />
                  </div>
                ))}
                <p
                  className={`text-center text-xs text-idc-body group-hover:text-idc-blue ${
                    selectedProductVariant ? "font-semibold text-idc-blue" : ""
                  }`}
                >
                  {product.title}
                </p>
              </div>
            </Link>
          )}
        </>
      ) : null}
    </>
  )
}

export default memo(RelatedVariant)
