import React, { useState, useEffect } from "react"
import { StoreContext } from "context/store-context"
import CartModal from "components/elements/CartModal"
import { Script } from "gatsby"
import bonusPackagesData from "./../../../data/bonusPackages.json"

export function AddToCart({
  title,
  price,
  tags,
  variantId,
  variantLegacyId,
  quantity,
  available,
  vendorName,
  customAttributes = {},
  productDetails,
  ...props
}) {
  const { addVariantToCart, loading, checkout } = React.useContext(StoreContext)
  const [isCartModalVisible, setIsCartModalVisible] = useState(false)
  const [, setReady] = useState(checkout.ready)
  {
    JSON.stringify(variantLegacyId)
  }

  useEffect(() => {
    setReady(checkout.ready)
  }, [checkout.ready])

  function addToCart(e) {
    e.preventDefault()
    const customAttributesList = []
    for (const [key, value] of Object.entries(customAttributes)) {
      customAttributesList.push({ key: key, value: value })
    }

    // if tag contains "WAR-FIN" then add a custom attribute automatically that says "Final Sale"
    if (tags.includes("WAR-FIN")) {
      customAttributesList.push({
        key: "Note",
        value:
          "This item is classified as a final sale, and can not be returned",
      })
    }

    // stringify all tags and add them as a custom attribute
    if (freeGiftEnabledVariant(price, tags)) {
      customAttributesList.push({ key: "Tags", value: "FG-BONUS-EG" })
      customAttributesList.push({
        key: "FreeGiftMessages",
        value: JSON.stringify(getFreeGiftMessages(price)),
      })
    }

    // add the product vendor name to the custom attributes list
    customAttributesList.push({ key: "Vendor", value: vendorName })

    // add the product type to the custom attributes list
    customAttributesList.push({
      key: "Product Type",
      value: productDetails.productType,
    })

    addVariantToCart(
      variantId,
      quantity,
      customAttributesList,
      productDetails,
      setIsCartModalVisible
    )
  }

  const preorder = tags?.filter((tag) => {
    if (tag.includes("preorder")) {
      return tag
    }
  })

  const boxsub = tags?.filter((tag) => {
    if (tag.includes("boxsub")) {
      return tag
    }
  })

  const freeGiftEnabledVariant = (productPrice, tags) => {
    return productPrice >= 500 && tags.includes("FG-BONUS-EG")
  }

  const getFreeGiftMessages = (productPrice) => {
    // Move message to an external file
    let messagesArray = [bonusPackagesData[0].message]
    if (productPrice >= 1000) {
      messagesArray.push(bonusPackagesData[1].message)
    }
    if (productPrice >= 2000) {
      messagesArray.push(bonusPackagesData[2].message)
    }

    return messagesArray
  }

  return (
    <>
      <CartModal
        setIsVisible={setIsCartModalVisible}
        isVisible={isCartModalVisible}
        loading={loading}
      />
      <button
        type="submit"
        className={`checkout-btn flex-grow items-center justify-center font-bold text-large h-14 rounded-full transition duration-300 ease-in-out ${
          available && !boxsub?.length
            ? "bg-idc-orange text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
            : "bg-gray-100 cursor-not-allowed"
        }`}
        onClick={addToCart}
        // disabled={!available || !ready || loading}
        disabled={!available || loading || boxsub?.length}
        {...props}
      >
        {loading ? (
          <span className="inline-block">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                fill="#fff"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="0.75s"
                  values="0 12 12;360 12 12"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </span>
        ) : (
          <span>
            {available
              ? preorder?.length
                ? "Pre Order"
                : boxsub?.length
                ? "Subscription Only"
                : "Add to Cart"
              : "Out of Stock"}
          </span>
        )}
      </button>
    </>
  )
}
