import React, { useState, useEffect } from "react"
import { cart } from "@boldcommerce/bold-subscriptions-js"
import QuantitySelect from "components/elements/QuantitySelect"

export interface ProductSubscriptionProps {
  groupId: string
  productId: string
  variantId: string
}

const ProductSubscription = ({
  variantMetafields,
  variantId,
  productId,
  boldGroupData,
}) => {
  const [, setCanBuildBox] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState({})
  const [frequencyType, setFrequencyType] = useState([])
  const [selectedFrequency, setSelectedFrequency] = useState({
    frequency_type_id: 2,
    frequency_type_translation: "week",
  })
  const [prepaidLength, setPrepaidLength] = useState([])
  const [, setSelectedPrepaidLength] = useState({})
  const [isPrepaid, setIsPrepaid] = useState(false)

  const [quantity, setQuantity] = useState(1)
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) window.myshopifyDomain = process.env.GATSBY_SHOPIFY_STORE_URL

  const metafieldsDict = {}
  if (variantMetafields && variantMetafields.length > 0) {
    variantMetafields.forEach((metafield) => {
      const { key, value } = metafield
      metafieldsDict[key] = value
    })
  }

  useEffect(() => {
    setCanBuildBox(boldGroupData.build_a_box.length > 0)
    setFrequencyType(boldGroupData.frequency_type)
    setPrepaidLength(boldGroupData.prepaid.lengths)
    setSubscriptionData(boldGroupData)
  }, [boldGroupData])

  const handleCheckboxChange = (e) => {
    setIsPrepaid(!isPrepaid)
  }

  const handleFrequencyChange = (event) => {
    // event.preventDefault()

    frequencyType.forEach((type) => {
      if (event.target.value === type.frequency_type_translation) {
        setSelectedFrequency(type)
      }
    })
  }

  const handlePrepaidLengthChange = (event) => {
    event.preventDefault()

    prepaidLength.forEach((item) => {
      if (event.target.value === item.id) {
        setSelectedPrepaidLength(item)
      }
    })
  }

  return (
    <>
      <form
        action={`https://recurringcheckout.com/s/${process.env.GATSBY_SHOPIFY_STORE_NAME}/checkout/recurring_product?shop_url=${process.env.GATSBY_SHOPIFY_STORE_URL}`}
        method="POST"
      >
        <input
          hidden
          name="group_id"
          defaultValue={subscriptionData.group_id}
          readOnly
        />
        <input
          hidden
          name="frequency_type"
          defaultValue={selectedFrequency.frequency_type_id}
          readOnly
        />
        <input hidden name="variant_id[]" defaultValue={variantId} readOnly />
        <input hidden name="product_id[]" defaultValue={productId} readOnly />
        {subscriptionData.csrf_token && (
          <input
            type="hidden"
            name={subscriptionData.csrf_token_name}
            defaultValue={subscriptionData.csrf_token}
          />
        )}
        {isPrepaid ||
          (subscriptionData.is_prepaid_only && (
            <>
              {" "}
              <input hidden name="is_prepaid" defaultValue={1} />
              <input hidden name="is_prepaid" defaultValue={1} />
              <input hidden name="is_gift" defaultValue={1} />
            </>
          ))}

        <div className="mb-4">
          <p className="text-idc-title block text-sm font-medium">
            Deliver Every
          </p>
          <div className="grid grid-cols-2 gap-2">
            <label className="sr-only" htmlFor="frequency_num">
              Choose Delivery Frequency
            </label>
            <select
              className="text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
              name="frequency_num"
              id="frequency_num"
            >
              {Array(subscriptionData?.frequency_num)
                .fill("")
                .map((item, index) => (
                  <option key={`frequency_num-${index}`} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
            </select>

            <label className="sr-only" htmlFor="frequency_type_text">
              Choose Delivery Interval
            </label>
            <select
              className="text-idc-blue font-medium capitalize mt-1 block pl-4 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
              name="frequency_type_text"
              id="frequency_type_text"
              onChange={handleFrequencyChange}
            >
              {frequencyType.map((type) => (
                <option
                  key={type.frequency_type_id}
                  value={type.frequency_type_translation}
                >
                  {type.frequency_type_translation}(s)
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label
            className="text-idc-title block text-sm font-medium"
            htmlFor="property-grind"
          >
            Choose a grind. 1 is finest and 12 is coarsest
          </label>
          <select
            className="text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
            name="properties[Grind]"
            id="property-grind"
          >
            <option value="Whole Bean">Whole Bean (Recommended)</option>
            <option value="1">1</option>
            <option value="2">2 - Espresso</option>
            <option value="3">3</option>
            <option value="4">4 - Aeropress</option>
            <option value="5">5</option>
            <option value="6">6 - Drip</option>
            <option value="7">7 - Pour Over</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12 - French Press</option>
          </select>
        </div>

        <div className="mb-4">
          <input hidden name="quantities[]" value={quantity} readOnly />
          <QuantitySelect quantity={quantity} setQuantity={setQuantity} />
        </div>
        {subscriptionData.prepaid && !subscriptionData.is_prepaid_only && (
          <label
            htmlFor="prepay"
            className="font-medium text-idc-title text-sm flex items-center cursor-pointer mb-4"
          >
            <div className="relative mr-2 p-[0.125rem] h-[1.25rem] w-[1.25rem] border border-gray-300 rounded-sm">
              <input
                checked={isPrepaid}
                id="prepay"
                type="checkbox"
                name="prepay"
                className="peer sr-only cursor-pointer"
                onChange={handleCheckboxChange}
              />
              <div
                className={`peer-focus-visible:ring-idc-blue peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2 cursor-pointer w-full h-full rounded-sm ${
                  isPrepaid ? "bg-idc-orange" : ""
                }`}
              ></div>
            </div>
            Make this a prepaid subscription.
          </label>
        )}

        {
          (isPrepaid || subscriptionData.is_prepaid_only) && (
            <div className="mb-4">
              <label
                className="text-idc-title block text-sm font-medium"
                htmlFor="frequency_type_text"
              >
                Subscription Length (# of shipments)
              </label>
              <select
                className="text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
                name="total_recurrences"
                id="prepaid_length_id"
                onChange={handlePrepaidLengthChange}
              >
                {prepaidLength.map((type) => (
                  <>
                    <option key={type.id} value={type.length}>
                      {type.length}
                    </option>
                  </>
                ))}
              </select>
            </div>
          )
          // print all the values of the form as filled out in a json string
        }
        <button
          className={`font-bold text-large w-full h-14 rounded-full bg-idc-orange text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue transition duration-300 ease-in-out`}
          onClick={cart.standard.directlyToCheckout}
        >
          Set Up Now
        </button>
      </form>
      {}
    </>
  )
}

export default ProductSubscription
