import React, { FC } from "react"

export interface FeatureWithIconProps {
  icon: string
  iconBgColor: string
  name: string
  description: string
}

const freeShipping = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 28">
      <path
        d="M15.575 17.529 25.218 5.61l-12.382 9.026-.115.116c-.193.192-.386.385-.502.617a1.756 1.756 0 0 0-.193.771c0 .27.039.54.116.771.077.27.232.463.424.695.193.193.425.347.656.463.232.115.502.154.771.154.27 0 .54-.039.772-.154.231-.116.463-.27.656-.463l.154-.077Z"
        fill="#fff"
      />
      <path
        d="m22.518 8.927-7.059 8.717h-.193c-.038.039-.154.077-.192.116-.81.771-2.122.463-2.855-.309-.154-.154-.038-.54-.115-.694 0 0-.116.386-.078.424.502.887 1.08 1.736 1.698 2.585.192.192.385.347.617.462.231.116.501.155.771.155s.54-.039.772-.116c.231-.077.462-.231.655-.424l.116-.116 7.097-8.756c-.308-.771-.733-1.427-1.234-2.044Z"
        fill="#273B45"
        fillOpacity=".25"
      />
      <path
        d="M25.601 16.179v-.386c-.077-2.507-1.003-4.937-2.584-6.904l2.507-3.086c.116-.154.116-.386-.039-.502-.115-.154-.347-.154-.501-.038l-12.42 9.064-.039.039-.077.077a2.383 2.383 0 0 0-.578.771 2.054 2.054 0 0 0-.232.926c0 .309.039.656.155.926.115.308.27.578.501.81.231.231.501.424.771.54.27.115.617.193.926.193.309 0 .617-.078.926-.193.308-.116.578-.309.771-.54l.154-.116 6.673-8.216c1.427 1.775 2.238 4.012 2.315 6.287H23.71c-.231 0-.386.155-.386.386 0 .232.155.386.386.386h1.119a10.64 10.64 0 0 1-2.16 6.094c-1.466 1.967-3.588 3.356-5.979 3.973-.733.193-1.504.27-2.276.309V25.86c0-.231-.154-.386-.385-.386-.232 0-.386.155-.386.386v1.119a10.757 10.757 0 0 1-4.089-.965c-2.237-1.003-4.05-2.777-5.168-4.975a12.689 12.689 0 0 1-1.08-4.436h1.118c.232 0 .386-.154.386-.386 0-.231-.154-.386-.386-.386H3.307c.038-.655.116-1.272.231-1.89.54-2.391 1.852-4.551 3.78-6.094a10.672 10.672 0 0 1 6.364-2.353v1.273c0 .232.155.386.386.386.232 0 .386-.154.386-.386V1.521h3.317c.232 0 .386-.154.386-.385 0-.232-.154-.386-.386-.386h-7.406c-.231 0-.385.154-.385.386 0 .231.154.385.385.385h3.317v3.163A11.575 11.575 0 0 0 6.817 7.23a9.729 9.729 0 0 0-1.543 1.543L3.268 7.384l1.235-1.62c.115-.154.115-.424-.078-.54-.154-.115-.424-.115-.54.077L.954 9.004c-.116.155-.116.425.077.54.077.077.154.116.232.116a.35.35 0 0 0 .308-.154l1.234-1.543L4.811 9.35a11.686 11.686 0 0 0-2.006 4.398 16.201 16.201 0 0 0-.27 2.391v.154c0 1.775.425 3.51 1.196 5.092a11.379 11.379 0 0 0 5.516 5.323 11.363 11.363 0 0 0 4.783 1.041c.964 0 1.89-.116 2.854-.347a11.741 11.741 0 0 0 6.403-4.243 11.528 11.528 0 0 0 2.314-6.904v-.077Zm-10.26.964-.193.193a1.62 1.62 0 0 1-.54.347c-.193.077-.424.154-.617.116a1.6 1.6 0 0 1-.617-.116 1.28 1.28 0 0 1-.54-.386 1.617 1.617 0 0 1-.347-.54 1.747 1.747 0 0 1-.116-.617c0-.231.077-.424.154-.617s.232-.386.425-.54c0 0 .038 0 .038-.039.039 0 .039-.038.039-.038l9.99-7.29-7.676 9.527Z"
        fill="#273B45"
      />
    </svg>
  )
}

const qualiftyFirst = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
      <g clipPath="url(#a)">
        <path
          d="M22.447 27.056a5.053 5.053 0 1 0 0-10.106 5.053 5.053 0 0 0 0 10.106Z"
          fill="#fff"
        />
        <path
          d="M23.566 23.893h-.579v-2.507c0-.232-.077-.463-.27-.617a.91.91 0 0 0-.617-.27h-.501c-.232 0-.386.154-.386.385 0 .232.154.386.386.386h.501c.039 0 .039 0 .077.038.039.04.039.04.039.078v2.507h-.579c-.231 0-.386.154-.386.386 0 .231.155.385.386.385h1.929c.231 0 .386-.154.386-.386 0-.23-.155-.385-.386-.385ZM22.254 20.151a.489.489 0 0 0 .231.04h.116c.116-.04.231-.078.309-.155.077-.077.154-.193.154-.309.038-.116 0-.231-.039-.347-.038-.116-.115-.231-.231-.27-.232-.154-.579-.116-.81.077a.639.639 0 0 0-.193.463c0 .116.039.232.116.347.115 0 .231.077.347.154Z"
          fill="#513F23"
        />
        <path
          opacity=".25"
          d="m8.214 15.098-.733 5.67h3.472l.308-2.584c.309-2.584.888-3.008.888-3.008l-.579-2.469h-.617c-.656 0-1.312.231-1.813.694a2.668 2.668 0 0 0-.926 1.697Z"
          fill="#513F23"
        />
        <path
          d="M27.886 22.003a5.432 5.432 0 0 0-5.439-5.439 5.432 5.432 0 0 0-5.438 5.439c0 1.967 1.08 3.703 2.661 4.667h-9.026l1.119-8.833c.154-1.234.386-1.813.54-2.121.116-.154.231-.309.386-.463.424-.386.964-.617 1.581-.579h2.777c.232 0 .386-.154.386-.385 0-.232-.154-.386-.386-.386H14.27c-.656 0-1.273.193-1.813.579l-.694-3.086a2.814 2.814 0 0 0-.964-1.813c-.54-.463-1.196-.733-1.89-.733H4.51c-.694 0-1.388.27-1.89.733-.54.463-.848 1.118-.964 1.813L.5 20.73c0 .116.039.232.077.309.077.077.193.154.309.154h9.643l-.733 5.786c0 .115.038.231.077.308a.438.438 0 0 0 .308.116h12.343c2.97-.039 5.362-2.43 5.362-5.4ZM1.31 20.422l1.119-8.949c.077-.502.308-.964.694-1.312.386-.347.887-.54 1.388-.54h4.32c.502 0 1.003.193 1.389.54.386.348.617.81.694 1.35l.81 3.55a.84.84 0 0 0-.115.192 3.6 3.6 0 0 0-.54 1.389l-.116 1.08c0 .115-.039.27-.039.385l-.27 2.315H1.31Zm21.137 6.248a4.659 4.659 0 0 1-4.667-4.667c0-2.584 2.121-4.667 4.667-4.667s4.667 2.083 4.667 4.667a4.659 4.659 0 0 1-4.667 4.667Z"
          fill="#513F23"
        />
        <path
          d="M16.623 13.093a4.132 4.132 0 0 0 4.127-4.127 4.132 4.132 0 0 0-4.127-4.127 4.132 4.132 0 0 0-4.127 4.127c0 2.276 1.89 4.127 4.127 4.127Zm0-7.483a3.358 3.358 0 0 1 3.356 3.356 3.358 3.358 0 0 1-3.356 3.356 3.358 3.358 0 0 1-3.356-3.356c0-1.852 1.543-3.356 3.356-3.356ZM6.71 8.233a3.745 3.745 0 0 0 3.742-3.742C10.452 2.447 8.754.75 6.71.75S2.97 2.447 2.97 4.491A3.745 3.745 0 0 0 6.71 8.233Zm0-6.712a2.971 2.971 0 0 1 0 5.94 2.971 2.971 0 0 1 0-5.94Z"
          fill="#513F23"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.5 .75)"
            d="M0 0h27.386v27H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const vimProgram = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 28">
      <g clipPath="url(#a)">
        <path
          d="m11.716 24.765.07-21.11h-1.472l-.176 19.637H.601v1.473h11.115Z"
          fill="#2D3E34"
          opacity=".25"
        />
        <path
          d="M25.146 14.596h-5.4c-.21 0-.35.14-.35.35v.386h-.527v-1.507h2.35c.21 0 .35-.14.35-.351V5.058c0-.981-.77-1.753-1.753-1.753H3.16c-.946 0-1.718.737-1.753 1.648l-.49 9.222c0 .21.14.351.315.386a.41.41 0 0 0 .386-.316l.49-9.222c.036-.56.491-.981 1.052-.981h8.38v20.407H1.093l.21-4.663c0-.21-.14-.351-.35-.351-.176 0-.351.14-.351.35L.355 24.73.25 26.939c0 .105.035.175.105.245.07.07.176.106.246.106h20.618c.21 0 .35-.14.35-.351v-2.21c0-.21-.14-.35-.35-.35h-8.977V13.825h2.244v2.84c0 .21.14.35.351.35h3.682c.21 0 .35-.14.35-.35v-.631h.526v.386c0 .21.14.35.351.35h5.4c.21 0 .35-.14.35-.35v-1.473c0-.21-.14-.35-.35-.35Zm-4.278 10.52v1.507H.986l.07-1.507h19.812Zm-2.7-10.274h-2.98v-1.017h2.98v1.017Zm-5.926-1.719V4.006h7.574c.596 0 1.052.456 1.052 1.052v8.065h-8.626Zm2.946 3.191v-.771h2.98v.771h-2.98Zm9.607-.245h-4.698v-.772h4.698v.772Z"
          fill="#2D3E34"
        />
        <path
          d="M4.773 13.72c-.175 0-.35.14-.35.35l-.176 4.91c0 .42.14.806.42 1.121.281.316.667.491 1.088.491h.07c.386 0 .771-.14 1.087-.42.316-.281.49-.667.49-1.088l.352-9.818a1.597 1.597 0 0 0-1.508-1.648 1.597 1.597 0 0 0-1.648 1.508l-.07 1.823c0 .21.14.351.35.351.21 0 .35-.14.35-.35L5.3 9.125c0-.49.42-.877.912-.842.49 0 .876.421.841.912l-.35 9.818a.749.749 0 0 1-.28.596.845.845 0 0 1-.632.246.749.749 0 0 1-.596-.28 1.008 1.008 0 0 1-.246-.632l.176-4.909c0-.14-.14-.316-.351-.316Z"
          fill="#2D3E34"
        />
        <path
          d="M25.321 2.358h-.315A5.675 5.675 0 0 1 21.114.851a5.675 5.675 0 0 1-3.893 1.507h-.315c-.105.491-.175.982-.175 1.508 0 2.77 1.858 5.12 4.383 5.75 2.524-.665 4.383-2.98 4.383-5.75 0-.526-.07-1.017-.176-1.508Z"
          fill="#fff"
        />
        <path
          d="M21.114 9.967h-.105c-2.736-.736-4.63-3.226-4.63-6.1a6.1 6.1 0 0 1 .211-1.579c.035-.175.21-.28.35-.28 1.439.07 2.876-.421 3.928-1.403.14-.105.35-.105.456 0a5.49 5.49 0 0 0 3.927 1.403c.176 0 .316.105.351.28.175.491.245 1.017.245 1.578 0 2.875-1.893 5.4-4.628 6.101h-.105ZM17.186 2.71a6.45 6.45 0 0 0-.105 1.157c0 2.525 1.648 4.734 4.033 5.4 2.384-.666 4.032-2.875 4.032-5.4 0-.386-.035-.771-.105-1.122a6.19 6.19 0 0 1-3.927-1.403 6.362 6.362 0 0 1-3.928 1.368Zm3.437 3.962a.318.318 0 0 1-.246-.105l-.982-.982a.339.339 0 0 1 0-.49c.14-.14.351-.14.491 0l.737.736 1.683-1.718c.14-.14.35-.14.49 0 .141.14.141.35 0 .49l-1.963 2c-.035.034-.105.07-.21.07Z"
          fill="#2D3E34"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.25 .5)"
            d="M0 0h25.597v27H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const RenderIcon = (icon: string) => {
  if (icon === "free-shipping") return freeShipping()
  if (icon === "quality-first") return qualiftyFirst()
  if (icon === "vim-program") return vimProgram()
}

const FeatureWithIcon: FC<FeatureWithIconProps> = ({
  icon,
  iconBgColor = "bg-gray-300",
  name,
  description,
  children,
}) => {
  return (
    <div className="text-center">
      <div className={`${iconBgColor} w-12 h-12 p-3 mb-3 mx-auto rounded-full`}>
        {RenderIcon(icon)}
      </div>
      <p className="font-bold text-sm mb-1">{name}</p>
      <p className="text-xs text-idc-body">{description}</p>
      {children}
    </div>
  )
}

export default FeatureWithIcon
