import React, { FC, useEffect } from "react"

export interface GrindSelectProps {
  productType: string
  customAttributes: object
  setCustomAttributes: any
}
const GrindSelect: FC<GrindSelectProps> = ({
  productType,
  customAttributes,
  setCustomAttributes,
}) => {
  const handleSelect = (e) => {
    setCustomAttributes({
      ...customAttributes,
      [e.target.name]: e.target.value,
    })
  }

  // whole bean grind by default
  useEffect(() => {
    if (productType === "Fresh Coffee") {
      setCustomAttributes({
        ...customAttributes,
        Grind: "Whole Bean",
      })
    }
  }, [])

  return (
    <>
      {productType === "Fresh Coffee" && (
        <div className="mb-4">
          <label
            className="text-idc-title block text-sm font-medium"
            htmlFor="property-grind"
          >
            Choose a grind. 1 is finest and 12 is coarsest
          </label>
          <select
            className="text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
            onChange={handleSelect}
            name="Grind"
            id="property-grind"
          >
            <option value="Whole Bean">Whole Bean (Recommended)</option>
            <option value="1">1</option>
            <option value="2">2 - Espresso</option>
            <option value="3">3</option>
            <option value="4">4 - Aeropress</option>
            <option value="5">5</option>
            <option value="6">6 - Drip</option>
            <option value="7">7 - Pour Over</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12 - French Press</option>
          </select>
        </div>
      )}
    </>
  )
}

export default GrindSelect
