import React, { useState, useEffect, Fragment } from "react"
import { Tab, Transition } from "@headlessui/react"
import { Link, Script } from "gatsby"
import { navigate } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"
import ProductSummary from "components/elements/ProductSummary"
import VariantButtons from "components/elements/VariantButtons"
import ProductButtons from "components/elements/ProductButtons"
import GrindSelect from "components/elements/GrindSelect"
import ProductSubscriptions from "components/elements/ProductSubscriptions"
import QuantitySelect from "components/elements/QuantitySelect"
import { formatPrice } from "utils/format-price"
import slugify from "@sindresorhus/slugify"
import { AddToCartCombo } from "components/elements/AddToCart/add-to-cart-combo"
import { GatsbyImage } from "gatsby-plugin-image"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { ChevronUpIcon } from "@heroicons/react/solid"
import FeaturesWithIconLayout from "../FeaturesWithIconLayout"

const ProductDetailsLayout = ({
  productSummary,
  variantData,
  grindData,
  quantityData,
  productSubscriptionsData,
  buttonData,
  comboData,
}) => {
  const [boldGroupData, setBoldGroupData] = useState({
    build_a_box: [],
    frequency_type: [],
    prepaid: { lengths: [] },
    group_discount: 0,
  })
  const metafieldsDict = {}
  if (
    productSubscriptionsData.variantMetafields &&
    productSubscriptionsData.variantMetafields.length > 0
  ) {
    productSubscriptionsData.variantMetafields.forEach((metafield) => {
      const { key, value } = metafield
      metafieldsDict[key] = value
    })
  }

  const isSubscriptionItem: Boolean = Boolean(metafieldsDict?.rp_group_id)

  {
    if (!productSummary.onlineStoreUrl) {
      if (typeof window !== "undefined") {
        navigate("/collections/" + slugify(productSummary.vendorName))
      }

      return (
        <>
          <div>
            This product no longer exists. You are being redirected to the
            collection page.
            <div className="flex overflow-hidden fixed top-0 right-0 bottom-0 left-0 flex-col justify-center items-center w-full h-screen bg-gray-700 opacity-75 z-100">
              <div className="mb-4 w-12 h-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear loader"></div>
              <h2 className="text-xl font-semibold text-center text-white">
                Loading...
              </h2>
              <p className="w-1/3 text-center text-white">
                This may take a few seconds, please don't close this page.
              </p>
            </div>
          </div>
        </>
      )
    }

    {
      if (productSummary.onlineStoreUrl.includes("subscription-tier")) {
        if (typeof window !== "undefined") {
          navigate("/coffee-subscription")
        }

        return (
          <>
            <div className="h-screen">
              Redirecting to Subscription Page.
              <div className="flex overflow-hidden fixed top-0 right-0 bottom-0 left-0 flex-col justify-center items-center w-full h-screen bg-gray-700 opacity-75 z-100">
                <div className="mb-4 w-12 h-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear loader"></div>
                <h2 className="text-xl font-semibold text-center text-white">
                  Loading...
                </h2>
                <p className="w-1/3 text-center text-white">
                  This may take a few seconds, please don't close this page.
                </p>
              </div>
            </div>
          </>
        )
      }

      const buybox = productSummary.metafields?.filter((node) => {
        if (node.namespace.includes("buybox")) {
          return node
        }
      })

      if (!isSubscriptionItem) {
        return (
          <>
            <div className="">
              <ProductSummary {...productSummary} display="price" />
            </div>
            <VariantButtons {...variantData} />
            <div className="mb-4 md:mb-8">
              <GrindSelect {...grindData} />
              <QuantitySelect {...quantityData} />
            </div>
            <FeaturesWithIconLayout
              variantPrice={buttonData.variantPrice}
              tags={buttonData.tags}
              available={buttonData.available}
              totalInventory={buttonData.totalInventory}
            />{" "}
            <br />
            <ProductButtons {...productSummary} {...buttonData} />
            <br />
            {
              //only if there is more than one item in the comboData.products array
              comboData.products.length > 1 ? (
                <Disclosure
                  //open by default
                  defaultOpen={true}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-center items-center px-4 py-2 w-full text-sm font-semibold tracking-wider text-white rounded-lg bg-idc-dark-green hover:bg-idc-light-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        {open
                          ? "Hide combo savings"
                          : "Bigger savings when combined with these grinders" +
                            ""}{" "}
                        {open ? (
                          <ChevronUpIcon className="ml-2 w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="ml-2 w-5 h-5" />
                        )}
                      </Disclosure.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        // open the combo options as if opening a scroll. This is a bit of a hack, but it works.
                        enter="transition scroll ease-in-out duration-500"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Disclosure.Panel className="px-4 pt-4 pb-2 rounded-lg border-2 border-idc-dark-green">
                          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            {comboData.products.map((product) => (
                              <div
                                key={product.id}
                                className="flex overflow-hidden flex-col p-2 bg-white rounded-lg shadow"
                              >
                                <div className="flex-shrink-0">
                                  <GatsbyImage
                                    image={
                                      variantData.variants[0].product
                                        .featuredImage.gatsbyImageData
                                    }
                                    alt={variantData.variants[0].displayName}
                                    objectFit="scale-down"
                                  />
                                </div>
                                <div className="flex flex-col flex-1">
                                  <h3 className="flex-1 font-semibold text-gray-900 text-md"></h3>
                                  <Link to={`/products/${product.handle}`}>
                                    <GatsbyImage
                                      image={
                                        product.images[0]?.product.featuredImage
                                          .gatsbyImageData
                                      }
                                      alt={product.title}
                                    />{" "}
                                  </Link>
                                </div>
                                <div className="flex flex-col flex-1">
                                  <h3 className="flex-1 text-xs font-semibold text-gray-900">
                                    {variantData.variants[0].displayName.replace(
                                      " - Default Title",
                                      ""
                                    )}{" "}
                                    + {product.title}
                                  </h3>
                                  <p className="text-center">
                                    <span className="text-gray-700 line-through">
                                      $
                                      {
                                        // product?.variants[0]?.price + 0.99
                                        // that doesn't add, it just concatenates. I don't know why. to fix, Use parseFloat
                                      }
                                      {parseFloat(product?.variants[0]?.price) +
                                        parseFloat(
                                          variantData.variants[0].price
                                        )}
                                    </span>{" "}
                                    <span className="font-semibold text-idc-title">
                                      $
                                      {(
                                        parseFloat(
                                          product?.variants[0]?.price
                                        ) +
                                        parseFloat(
                                          variantData.variants[0].price
                                        ) *
                                          0.9
                                      ).toFixed(2)}
                                    </span>
                                  </p>
                                  <span className="font-semibold text-idc-title"></span>{" "}
                                  <AddToCartCombo
                                    variantIds={
                                      product?.variants[0]?.storefrontId +
                                      "," +
                                      variantData.variantId
                                    }
                                    quantities={(1, 1)}
                                    comboVariantId={variantData.variantId}
                                    tags={product?.tags}
                                    available={true}
                                    vendorName={product.vendor}
                                    discountCode={
                                      product.metafields?.filter(
                                        (node: {
                                          namespace: string | string[]
                                          key: string | string[]
                                        }) => {
                                          if (
                                            node.namespace.includes("combo") &&
                                            node.key.includes("coupon")
                                          ) {
                                            return node
                                          }
                                        }
                                      )[0]?.value
                                    }
                                    productDetails={{
                                      productType: product.productType,
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ) : null
            }
            {buybox ? (
              <div className="py-6">
                {buybox?.map((node: { value: any }) => (
                  <div
                    className="text-idc-body prose prose-sm prose-headings:text-idc-body-title prose-strong:text-idc-body-title prose-a:text-idc-orange prose-h3:text-xl prose-h4:text-lg"
                    dangerouslySetInnerHTML={{ __html: node.value }}
                  ></div>
                ))}
              </div>
            ) : (
              ""
            )}
          </>
        )
      } else {
        useEffect(() => {
          fetch(
            `https://ro.boldapps.net/api/widget/group/${metafieldsDict?.rp_group_id}?shop=${process.env.GATSBY_SHOPIFY_STORE_URL}`
          )
            .then((response) => response.json())
            .then((data) => {
              setBoldGroupData(data)
            })
            .catch((err) => console.warn(err))
        }, [])

        const hasDiscount = boldGroupData.group_discount > 0
        const discountPercentage = `${boldGroupData.group_discount}%`
        const discountedPrice = formatPrice(
          productSummary.currencyCode,
          productSummary.unformattedPrice *
            (1 - boldGroupData.group_discount / 100)
        )

        return (
          <>
            <div id="productSummary" className="hidden md:block">
              <ProductSummary {...productSummary} display="title" />
            </div>

            <div className="rounded-2xl border border-idc-border">
              <Tab.Group>
                <Tab.List className="grid grid-cols-2 rounded-t-2xl">
                  <Tab as={React.Fragment}>
                    {({ selected }) => (
                      <button
                        className={`py-4 px-6 rounded-tl-2xl text-idc-body-title text-left ${
                          selected
                            ? "bg-white"
                            : "border-r border-b bg-idc-light border-idc-border"
                        }`}
                      >
                        <p className="font-bold">One Time Purchase</p>
                        {boldGroupData.is_prepaid_only ? (
                          <p className="text-2xl font-medium">
                            ${productSummary.unformattedSalePrice}
                          </p>
                        ) : (
                          <p className="text-2xl font-medium">
                            {productSummary.price}
                          </p>
                        )}
                      </button>
                    )}
                  </Tab>
                  <Tab as={React.Fragment}>
                    {({ selected }) => (
                      <button
                        className={`py-4 px-6 rounded-tr-2xl text-idc-body-title text-left ${
                          selected
                            ? "bg-white"
                            : "border-b border-l bg-idc-light border-idc-border"
                        }`}
                      >
                        <p
                          className={`font-bold ${
                            hasDiscount ? "text-idc-orange" : ""
                          }`}
                        >
                          {hasDiscount
                            ? `Subscribe & Save ${discountPercentage}`
                            : "Subscription"}
                        </p>
                        <div className="flex flex-col items-baseline lg:flex-row lg:items-end">
                          <p className="mr-3 text-2xl font-medium">
                            {hasDiscount
                              ? discountedPrice
                              : productSummary.price}
                          </p>
                          <p className="text-sm text-idc-subtle">
                            per shipment
                          </p>
                        </div>
                        {hasDiscount && (
                          <p className="line-through text-idc-subtle">
                            {productSummary.price}
                          </p>
                        )}
                        {productSummary.unformattedSalePrice ? (
                          <span className="line-through text-idc-subtle">
                            ${productSummary.unformattedSalePrice}
                          </span>
                        ) : null}
                      </button>
                    )}
                  </Tab>
                </Tab.List>
                <Tab.Panels className="">
                  <Tab.Panel className="p-6">
                    <VariantButtons {...variantData} />
                    <div className="mb-4 md:mb-8">
                      <GrindSelect {...grindData} />
                      <QuantitySelect {...quantityData} />
                    </div>
                    <ProductButtons {...buttonData} />
                  </Tab.Panel>

                  <Tab.Panel className="p-6">
                    <ProductSubscriptions
                      boldGroupData={boldGroupData}
                      {...productSubscriptionsData}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>

            <Link
              to="/coffee-subscription"
              className="flex justify-between items-center rounded-2xl bg-idc-light-green py-5 px-8 mt-8 mb-12 lg:mb-0 text-[#2D3E34] duration-200 hover:bg-idc-light"
            >
              <div>
                <p className="font-semibold text-lg text-[#355944]">
                  Save Even More!
                </p>
                <p>Build your own subscription box</p>
              </div>
              <ChevronRightIcon className="w-6 h-6 shrink-0" />
            </Link>
          </>
        )
      }
    }
  }
}
export default ProductDetailsLayout
