import React, { useState, useEffect } from "react"
import { StoreContext } from "context/store-context"
import CartModal from "components/elements/CartModal"

export function AddToCartCombo({
  variantIds,
  variantLegacyIds,
  quantities,
  discountCode,
  vendorName,
  available,
  customAttributes = {},
  productDetails,
  variants,
  ...props
}) {
  const { addMultipleVariantsToCart, loading, checkout } =
    React.useContext(StoreContext)
  const [isCartModalVisible, setIsCartModalVisible] = useState(false)
  const [, setReady] = useState(checkout.ready)
  {
    JSON.stringify(variantLegacyIds)
  }

  useEffect(() => {
    setReady(checkout.ready)
  }, [checkout.ready])

  function addToCart(e) {
    e.preventDefault()
    const customAttributesList = []
    for (const [key, value] of Object.entries(customAttributes)) {
      customAttributesList.push({ key: key, value: value })
    }

    customAttributesList.push({ key: "Vendor", value: vendorName })

    customAttributesList.push({
      key: "Product Type",
      value: productDetails.productType,
    })

    addMultipleVariantsToCart(
      variantIds,
      quantities,
      customAttributesList,
      productDetails,
      variants
    )

    setIsCartModalVisible(true)
  }

  // combine the couponCode array into a string
  const couponCodeString = discountCode
  // send the couponCodeString to the store context
  const couponCodeStringContext = React.useContext(StoreContext)
  couponCodeStringContext.discountCode = couponCodeString

  //save code to local storage if using a browser
  if (typeof window !== "undefined") {
    if (couponCodeString) {
      localStorage.setItem("couponCode", couponCodeString)
    }
  }

  return (
    <>
      <CartModal
        setIsVisible={setIsCartModalVisible}
        isVisible={isCartModalVisible && !loading}
        loading={loading}
      />
      <button
        type="submit"
        className={`items-center ${
          available
            ? "p-2 bg-idc-orange w-full text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue rounded-full"
            : "bg-gray-100 cursor-not-allowed"
        }`}
        onClick={addToCart}
        disabled={!available || loading}
        {...props}
      >
        {loading ? (
          <span className="inline-block">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                fill="#fff"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="0.75s"
                  values="0 12 12;360 12 12"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </span>
        ) : (
          <span className="text-sm font-bold -tracking-tighter">
            {available ? "Add Both to Cart" : "Out of Stock"}
          </span>
        )}
      </button>
    </>
  )
}
