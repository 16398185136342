import React, { FC } from "react"
import { Link } from "gatsby"

export interface BreadcrumbProps {
  breadcrumbs: Array<any>
}

const Breadcrumbs: FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  return (
    <>
      {breadcrumbs &&
        breadcrumbs.map((link, index) => {
          const linkSlug = link.slug.startsWith("/products/")
            ? link.slug.replace("/products/", "/collections/")
            : link.slug

          return (
            <div key={link.name} className="flex flex-row items-center mr-2">
              <Link
                to={linkSlug}
                className="flex flex-row text-sm leading-4 text-idc-body capitalize mr-2 hover:text-idc-darker-blue active:text-idc-darker-blue transition duration-300 ease-in-out"
              >
                {link.name}
              </Link>
              {index !== breadcrumbs.length - 1 && (
                <svg
                  width={7}
                  height={11}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m1 10 5-4.5L1 1" stroke="#CAC6C1" />
                </svg>
              )}
            </div>
          )
        })}
    </>
  )
}

export default Breadcrumbs
