import React, { FC, memo } from "react"
import ArrowLink from "components/elements/Link"
import useWindowDimensions from "utils/dimensions"
import ProductCard, { ProductCardProps } from "components/elements/ProductCard"
import Slider from "../Slider"
import { v4 as uuid } from "uuid"

export interface ListingDetailsProps {
  title: string
  desc?: string
  url?: string
  linkText?: string
  linkBottom?: boolean
}
export interface ProductListingProps {
  products: ProductCardProps[]
  scrollable: boolean
  listingDetails?: ListingDetailsProps
  columns?: number
  shopifyCollection?: string
  isCart: boolean
}

const ProductListing: FC<ProductListingProps> = ({
  products,
  scrollable,
  listingDetails,
  columns,
  isCart,
}) => {
  const isBrowser = typeof window !== "undefined"
  let isSmallScreen
  if (isBrowser) {
    const { width } = useWindowDimensions()
    isSmallScreen = width < 1024
  }

  return (
    <div className="my-16">
      {listingDetails ? (
        <div className="mb-6">
          <h2
            className={`m-0 mb-1 text-xl ${
              isCart ? "lg:text-2xl" : "lg:text-3xl"
            }
           font-bold`}
          >
            {listingDetails.title}
          </h2>
          <div className="flex flex-row justify-between">
            <p className="font-normal text-sm lg:text-base text-idc-body m-0">
              {listingDetails.desc}
            </p>
            {!listingDetails.linkBottom &&
            !isSmallScreen &&
            listingDetails.url ? (
              <div>
                <ArrowLink
                  text={listingDetails.linkText}
                  url={listingDetails.url ?? "/"}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {scrollable || isSmallScreen ? (
        <Slider
          items={products.map((product) => (
            <ProductCard product={product} key={uuid()} />
          ))}
          responsive={true}
        />
      ) : (
        <div
          className={`grid gap-x-8 gap-y-16 grid-cols-1 sm:grid-cols-2 ${
            columns && columns === 5 ? "md:grid-cols-5" : "md:grid-cols-4"
          }`}
        >
          {products.length
            ? products.map((product) => {
                return <ProductCard product={product} key={uuid()} />
              })
            : null}
        </div>
      )}

      {listingDetails &&
      (listingDetails.linkBottom || isSmallScreen) &&
      listingDetails?.url ? (
        <div className="flex flex-row justify-center mt-6">
          <ArrowLink
            text={listingDetails.linkText}
            url={listingDetails.url ?? "/"}
          />
        </div>
      ) : null}
    </div>
  )
}

export default memo(ProductListing)
