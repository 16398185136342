import React, { useRef } from "react"
import { v4 as uuid } from "uuid"
import { useHasBeenVisible } from "hooks/useVisibility"

const ScrollLoadLayout = ({ children }: { children: any }) => {
  const section = useRef()
  const preload = useRef()
  const hasScrolled = useHasBeenVisible(section)
  const isScrolling = useHasBeenVisible(preload)

  return (
    <>
      {hasScrolled || isScrolling ? (
        <div key={uuid()}>{children}</div>
      ) : (
        <div ref={section}></div>
      )}
    </>
  )
}

export default ScrollLoadLayout
