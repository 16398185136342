import * as React from "react"
import { StoreContext } from "context/store-context"
import { graphql } from "gatsby"
import { Layout } from "components/layouts/Layout/layout"
import { getSrc } from "gatsby-plugin-image"
import {
  formatPrice,
  getAmmountSaved,
  getRentPrice,
  getLeasePrice,
} from "utils/format-price"
import { Seo } from "components/seo"
import { Helmet } from "react-helmet"
import ScrollLoadLayout from "components/layouts/ScrollLoadLayout"
import ProductScreen from "components/screens/ProductScreen"
import Addons from "components/elements/Addons"
import slugify from "slugify"
import { useLocation } from "@reach/router"

export default function Product({
  data: { product, productvideos, combos },
  pageContext,
}) {
  const {
    hasOnlyDefaultVariant,
    priceRangeV2,
    title,
    tags,
    tag,
    handle,
    productType,
    totalInventory,
    legacyResourceId,
    storefrontId,
    onlineStoreUrl,
    onlineStorePreviewUrl,
    vendor,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
    metafields,
  } = product
  const location = useLocation()

  const { client } = React.useContext(StoreContext)
  const variants = product.variants.filter((variant) => variant.price > 0)
  const initialVariant = variants[0]

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const [customAttributes, setCustomAttributes] = React.useState({})

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const [variantsInfo, setVariantsInfo] = React.useState()

  React.useEffect(() => {
    let params = new URL(location.href).searchParams
    if (params?.get("variant")) {
      setVariant(
        variants.find(
          (variant) => variant?.legacyResourceId === params?.get("variant")
        ) || variants[0]
      )
    }
  }, [])

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        setAvailable(
          fetchedProduct?.variants.find(
            (variant) =>
              variant.id ===
              `gid://shopify/ProductVariant/${productVariant.legacyResourceId}`
          )?.available
        )
        setVariantsInfo(fetchedProduct.variants)
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (value, selectedVariant) => {
    if (value === "") {
      return
    }

    // const currentOptions = [...variant.selectedOptions]

    // currentOptions[index] = {
    //   ...currentOptions[index],
    //   value,
    // }

    // const selectedVariant = variants.find((variant) => {
    //   return isEqual(currentOptions, variant.selectedOptions)
    // })
    setVariant({ ...selectedVariant })
  }

  const sendGTMViewItem = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      const merchantID = `shopify_CA_${legacyResourceId}_${variant.legacyResourceId}`

      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        value: parseFloat(variant.price),
        currency: product.priceRangeV2.maxVariantPrice.currencyCode,
        ecommerce: {
          items: [
            {
              item_name: product.title,
              item_id: merchantID,
              id: merchantID,
              price: parseFloat(variant.price),
              quantity: 1,
              google_business_vertical: "retail",
            },
          ],
        },
      })
    }
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  React.useLayoutEffect(() => {
    if (variant) {
      sendGTMViewItem()
    }
  }, [])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const sale_price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant?.compareAtPrice
  )

  const ammount_saved = getAmmountSaved(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price,
    variant?.compareAtPrice
  )

  const rentPrice = getRentPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const leasePrice = getLeasePrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1

  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  let metafieldsDict = {}
  metafields.forEach((metafield) => (metafieldsDict[metafield.key] = metafield))

  const productVariants = metafieldsDict["product_variants"]?.value
    ? metafieldsDict["product_variants"]?.value
    : ""

  const keyfeaturetitle = metafieldsDict["key_feature_title"]?.value
    ? metafieldsDict["key_feature_title"]?.value
    : "[]"
  const keyfeaturecopy = metafieldsDict["key_feature_copy"]?.value
    ? metafieldsDict["key_feature_copy"]?.value
    : "[]"

  const featureimage = metafieldsDict["feature_image"]?.value
    ? metafieldsDict["feature_image"]?.value
    : "[]"
  const featuretitle = metafieldsDict["feature_title"]?.value
    ? metafieldsDict["feature_title"]?.value
    : "[]"
  const featurecopy = metafieldsDict["feature_copy"]?.value
    ? metafieldsDict["feature_copy"]?.value
    : "[]"

  const specificationtitle = metafieldsDict["specification_title"]?.value
    ? metafieldsDict["specification_title"]?.value
    : "[]"
  const specificationcopy = metafieldsDict["specification_copy"]?.value
    ? metafieldsDict["specification_copy"]?.value
    : "[]"

  const warrantytitle = metafieldsDict["warranty_title"]?.value
    ? metafieldsDict["warranty_title"]?.value
    : "[]"
  const warrantycopy = metafieldsDict["warranty_copy"]?.value
    ? metafieldsDict["warranty_copy"]?.value
    : "[]"
  const boxcontents = metafieldsDict["box_content"]?.value
    ? metafieldsDict["box_content"]?.value
    : "[]"

  // const videoRegex = /(data-src=\\"[0-9,A-z,:,\/,.,]+\\")/
  // const videoString = '<iframe width="560" height="315" src="https://www.youtube.com/embed/daHv3ucfsYE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  const videoRegex = /https?:\/\/[^\s"<>]+/
  const videoField = metafields?.filter((node) => {
    return node.value.includes("youtube.com")
  })
  const videoURL = videoRegex.exec(videoField[0]?.value)?.[0] ?? null

  const productScreenData = {
    imageData: {
      hasImages,
      hasMultipleImages,
      images,
      activeVariant: variant,
      productVideo: productvideos?.video[0].url,
      prodVidThumb: productvideos?.thumbnail?.url,
    },
    videoData: {
      url: videoURL,
    },
    productSummary: {
      sku: variant.sku,
      name: title,
      vendorName: vendor,
      // color: string,
      // reviewSummary: ReviewSummary,
      tags,
      onlineStoreUrl,
      currencyCode: priceRangeV2.minVariantPrice.currencyCode,
      unformattedPrice: variant.price,
      unformattedSalePrice: variant?.compareAtPrice,
      price,
      sale_price,
      rentPrice,
      leasePrice,
      ammount_saved,
      legacyResourceId,
      metafields,
      productType,
      tag,
      productVideo: productvideos?.video[0].url,
      prodVidThumb: productvideos?.thumbnail?.url,
      // reward_points: 0,
      // reward_points_value: 0,
    },
    variantData: {
      hasVariants: hasVariants,
      variantTitle: "Variants",
      hasOnlyDefaultVariant,
      variants: variants,
      variantId: productVariant.storefrontId,
      productVariants,
      variantsInfo,
      handleOptionChange,
    },
    grindData: {
      productType,
      customAttributes,
      setCustomAttributes,
    },
    quantityData: {
      quantity,
      setQuantity,
    },
    productSubscriptionsData: {
      variantId: variant.legacyResourceId,
      productId: legacyResourceId,
      variantMetafields: variant.metafields,
    },
    buttonData: {
      quantity,
      available,
      variantLegacyId: variant.legacyResourceId,
      productId: legacyResourceId,
      productUrl: onlineStorePreviewUrl,
      variantId: productVariant.storefrontId,
      variantPrice: productVariant.price,
      totalInventory: totalInventory,
      productType,
      storefrontId,
      handle,
      tags,
      customAttributes,
    },
    faqData: {
      metafields,
    },
    specificationData: {
      specificationTitle: specificationtitle,
      specificationCopy: specificationcopy,
      metafields,
    },
    keyFeatureData: {
      metafields,
      keyFeatureTitle: keyfeaturetitle,
      keyFeatureCopy: keyfeaturecopy,
    },
    overviewData: {
      metafields,
      description,
      descriptionHtml,
      tags,
      productType,
    },
    featureData: {
      metafields,
      featureImage: featureimage,
      featureTitle: featuretitle,
      featureCopy: featurecopy,
    },
    questionData: {
      productId: legacyResourceId,
      productName: title,
      productUrl: onlineStorePreviewUrl,
    },
    reviewData: {
      productId: legacyResourceId,
      productName: title,
      productUrl: onlineStorePreviewUrl,
    },
    warrantyAndContentsData: {
      metafields,
      tags,
      vendorName: vendor,
      warrantyTitle: warrantytitle,
      warrantyCopy: warrantycopy,
      boxContents: boxcontents,
    },
    comboData: {
      // id,
      // title,
      // handle,
      // tags,
      // variants,
      // images,
      // shopifyId,
      // vendor,
      // productType,
      // tag: "combo",
      products: combos.nodes.map((product) => {
        return { ...product, productImage: product.images?.[0] }
      }),
    },
  }

  const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    additionalType: "Dataset",
    "@id": `${productScreenData.buttonData.productUrl}#product`,
    name: productScreenData.productSummary.name,
    url: productScreenData.buttonData.productUrl,
    category: productScreenData.overviewData.productType,
    manufacturer: productScreenData.productSummary.vendorName,
    // "mpn": "BRE-ES870XL-B5",
    sku: productScreenData.productSummary.sku,
    audience: {
      "@type": "PeopleAudience",
      audienceType: "Coffee Drinkers, Home Baristas",
    },
    brand: {
      "@type": "Brand",
      url: `/collections/${productScreenData.productSummary.vendorName}`,
      name: productScreenData.productSummary.vendorName,
    },
    // "additionalProperty": [
    //   {
    //     "@type": "PropertyValue",
    //     "name": "Boiler Material",
    //     "value": "Stainless Steel"
    //   },
    //   {
    //     "@type": "PropertyValue",
    //     "name": "Boiler Size",
    //     "value": "N/A"
    //   },
    // ],

    // "height": "37.3 cm",
    // "width": "34.29 cm",
    // "depth": "27.9 cm",
    // "weight": "30.4 lbs",
    // "color": "Various",
    // "material": "Stainless Steel",
    description: productScreenData.productSummary.description,

    image: productScreenData.imageData.images.map((image) => getSrc(image)),
    offers: {
      "@type": "Offer",
      name: productScreenData.productSummary.name,
      priceCurrency: productScreenData.productSummary.currencyCode,
      priceValidUntil: "2022-12-31",
      price: productScreenData.productSummary.unformattedPrice,
      itemCondition: "http://schema.org/NewCondition",
      availability: productScreenData.buttonData.available
        ? "http://schema.org/InStock"
        : "http://schema.org/OutOfStock",
      // "url": "https://idrinkcoffee.com/products/breville-barista-express-espresso-machine-870xl?variant=16340161429538",
      url: productScreenData.productSummary.productUrl,
      priceSpecification: {
        "@type": "PriceSpecification",
        priceCurrency: productScreenData.productSummary.currencyCode,
        valueAddedTaxIncluded: "http://schema.org/False",
        price: productScreenData.productSummary.unformattedPrice,
        minPrice: priceRangeV2.minVariantPrice.amount,
        maxPrice: priceRangeV2.maxVariantPrice.amount,
        url: productScreenData.productSummary.productUrl,
      },
      seller: {
        "@type": "Organization",
        name: "iDrinkCoffee.com",
        url: "https://idrinkcoffee.com",
        ...(productScreenData.productSummary.productType !== "Fresh Coffee" && {
          hasProductReturnPolicy: {
            "@type": "http://schema.org/ProductReturnPolicy",
            productReturnLink: "https://idrinkcoffee.com/idc-service-guarantee",
            productReturnDays: "14",
          },
        }),
      },
    },
    ...(metafieldsDict?.reviews_count &&
      metafieldsDict?.reviews_average && {
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: metafieldsDict?.reviews_average?.value,
          url: productScreenData.productSummary.productUrl,
          reviewCount: metafieldsDict?.reviews_count?.value,
        },
      }),
  }

  const webpageSchema = {
    "@context": "http://schema.org/",
    "@type": "WebPage",
    "@id": `/products/${
      productScreenData?.productSummary?.productType
        ? `${slugify(productScreenData.productSummary.productType)}/`
        : ""
    }${productScreenData.buttonData.handle}#webpage`,
    url: `/products/${
      productScreenData?.productSummary?.productType
        ? `${slugify(productScreenData.productSummary.productType)}/`
        : ""
    }${productScreenData.buttonData.handle}`,
    inLanguage: "en-CA",
    name: productScreenData.productSummary.name,
    isPartOf: {
      "@id": "https://idrinkcoffee.com/#website",
    },
  }

  const brandSchema = {
    "@context": "http://www.schema.org",
    "@type": "http://schema.org/Brand",
    url: `/collections/${productScreenData.productSummary.vendorName}`,
    name: productScreenData.productSummary.vendorName,
  }
  return (
    <Layout>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(firstImage?.gatsbyImageData)}
        />
      ) : undefined}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(brandSchema)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(webpageSchema)}
        </script>
      </Helmet>
      <ProductScreen {...productScreenData} pageContext={pageContext} />
      <ScrollLoadLayout>
        <div className="mt-6 md:mt-8 lg:mt-12 px-4 md:px-6 lg:px-10 mx-auto max-w-[85rem]"></div>
        <div></div>
        {productScreenData.productSummary?.sku && (
          <div className="mt-6 md:mt-8 lg:mt-12 px-4 md:px-6 lg:px-10 mx-auto max-w-[85rem]">
            <Addons sku={productScreenData.productSummary.sku} />
          </div>
        )}
      </ScrollLoadLayout>
    </Layout>
  )
}

//I finally figured out whre the memory leak was coming from. It was in the ProductScreen component. Specifically, it was in the render function. I fixed it by wrapping the ProductScreen component in a memo function. here's how:
// export default memo(ProductScreen) //this is the new line
// export default ProductScreen //this is the original version

export const query = graphql`
  query ($id: String!, $handle: String!, $tag: String = "") {
    product: shopifyProduct(id: { eq: $id }, onlineStoreUrl: { ne: "null" }) {
      id
      legacyResourceId
      title
      totalInventory
      vendor
      handle
      description
      descriptionHtml
      productType
      onlineStorePreviewUrl
      onlineStoreUrl
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        altText
        id
        src
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 640)
      }
      hasOnlyDefaultVariant
      variants {
        id
        availableForSale
        compareAtPrice
        storefrontId
        title
        displayName
        price
        sku
        legacyResourceId
        selectedOptions {
          name
          value
        }
        image {
          gatsbyImageData
          originalSrc
          altText
          id
        }
        product {
          featuredImage {
            gatsbyImageData(
              height: 100
              width: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FIXED
            )
            altText
            id
          }
          id
        }
        metafields {
          key
          value
          id
          namespace
        }
      }
      options {
        name
        values
        id
      }
      metafields {
        id
        namespace
        value
        key
      }
    }
    combos: allShopifyProduct(limit: 500, filter: { tags: { eq: $tag } }) {
      nodes {
        id
        tags
        shopifyId
        vendor
        handle
        productType
        title
        variants {
          id
          storefrontId
          shopifyId
          price
          sku
          shopifyId
          displayName
        }
        metafields {
          id
          namespace
          value
          key
        }
        images {
          product {
            featuredImage {
              gatsbyImageData(
                height: 100
                width: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                layout: FIXED
              )
            }
          }
        }
      }
    }
    productvideos: strapiProductVideos(handle: { eq: $handle }) {
      strapiId
      video {
        url
        size
      }
      thumbnail {
        url
        width
      }
    }
  }
`
