import React, { FC } from "react"
import Ratings from "react-ratings-declarative"

export interface StarRatingProps {
  rating: number
  size?: string
  readonly: boolean
}

const StarRating: FC<StarRatingProps> = ({ rating }) => {
  return (
    <div>
      <Ratings
        rating={rating}
        widgetDimensions="14px"
        widgetSpacings="1px"
        widgetRatedColors="#F7C069"
        widgetEmptyColors="#968E8C"
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
    </div>
  )
}

export default StarRating
