import React, { FC, useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Listbox, Transition } from "@headlessui/react"
import ProductVariant from "components/elements/RelatedVariant"
import { sendSelectItemGTMEvent } from "utils/gtmEvents"

interface Variant {
  displayName: string
  selectedOptions: Array<{
    name: string
    value: string
  }>
  image: {
    id: string
    altText: string
    gatsbyImageData: any
  }
  product: {
    featuredImage: {
      id: string
      altText: string
      gatsbyImageData: any
    }
    productType: string
  }
  availableForSale: boolean
  storefrontId: string
}

export interface VariantButtonsProps {
  hasVariants: boolean
  variantTitle: string
  hasOnlyDefaultVariant: boolean
  variantId: string
  variants: Variant[]
  productVariants: string // manual link between separate products which are variants of each other
  handleOptionChange: (value: string, variant: any) => void
  variantsInfo?: any[]
}

const VariantButtons: FC<VariantButtonsProps> = ({
  hasVariants,
  variantTitle,
  variants,
  productVariants,
  handleOptionChange,
  variantId,
  variantsInfo,
  ...props
}) => {
  const parsedRelatedVariants: string[] = productVariants
    ? productVariants.split("|").map((variant) => variant.trim())
    : []

  const initialVariantIndex = variants?.findIndex(
    (variant) => variant.storefrontId === variantId
  )
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0)
  const [selectedCustomization, setSelectedCustomization] = useState(
    variants[0] //variant id prefered or first variant as fallback
  )

  useEffect(() => {
    if (initialVariantIndex !== selectedVariantIndex) {
      setSelectedVariantIndex(initialVariantIndex)
    }
  }, [initialVariantIndex])

  return (
    <>
      <div className={`mb-8 lg:mb-9 ${!productVariants ? "hidden" : ""}`}>
        <p className="font-semibold text-sm mb-2">{variantTitle}</p>
        <div className="flex flex-wrap items-start gap-3 md:gap-4">
          {/**
           * The next loop is only rendered for fake variant products like expresso machines
           */}
          {parsedRelatedVariants.map((productVariant) => (
            <React.Fragment key={productVariant}>
              <ProductVariant handle={productVariant} />
            </React.Fragment>
          ))}
        </div>

        {hasVariants ? (
          <div className="mt-4 md:mt-6">
            <p className="font-semibold text-sm mb-2">Customizations</p>
            <Listbox
              value={selectedCustomization}
              onChange={(variant) => {
                const variantInfo = variantsInfo?.find(
                  (variantInfo) => atob(variant.storefrontId) === variantInfo.id
                )
                const isAvailable = variantInfo
                  ? variantInfo.available
                  : variant.availableForSale
                if (isAvailable) {
                  const index = variants?.findIndex(
                    (variant1) => variant1.sku === variant.sku
                  )
                  handleOptionChange(variant.selectedOptions[0].value, variant)
                  setSelectedVariantIndex(index)
                  setSelectedCustomization(variant)
                }
              }}
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-3 pl-4 pr-10 text-left rounded-md cursor-default font-medium text-base text-idc-blue border border-gray-300 focus:outline-none focus-visible:border-idc-blue sm:text-sm">
                  <span className="block truncate">
                    {selectedCustomization.selectedOptions[0].value}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      width="20"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.833 7.5 10 13.333 4.167 7.5"
                        stroke="#A1A1AA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  as={React.Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-idc-blue ring-opacity-5 focus:outline-none sm:text-sm z-10">
                    {variants.map((variant, variantIndex) => (
                      <Listbox.Option
                        key={variantIndex}
                        className={({ active }) =>
                          ` select-none relative py-2 pl-10 pr-4 ${
                            active
                              ? "text-idc-blue bg-idc-light-blue"
                              : "text-idc-title-body"
                          } 
                            ${
                              variant.availableForSale
                                ? "cursor-default"
                                : "opacity-75 cursor-not-allowed"
                            }`
                        }
                        value={variant}
                        disabled={!variant.availableForSale}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected
                                  ? "font-medium text-idc-blue"
                                  : "font-normal"
                              }`}
                            >
                              {variant.selectedOptions[0].value}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-idc-blue">
                                <svg
                                  width="13"
                                  height="10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.536.764a.9.9 0 0 1 0 1.272l-7.2 7.2a.9.9 0 0 1-1.273 0l-3.6-3.6a.9.9 0 0 1 1.273-1.272l2.963 2.963L11.263.764a.9.9 0 0 1 1.273 0Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        ) : null}
      </div>

      <div
        className={`mb-8 lg:mb-9 ${
          !hasVariants || productVariants ? "hidden" : ""
        }`}
        {...props}
      >
        <p className="font-semibold text-sm mb-2">{variantTitle}</p>
        <div className="flex flex-wrap items-start gap-4">
          {variants.map((variant, index) => {
            const variantInfo = variantsInfo?.find(
              (variantInfo) => variant.storefrontId === variantInfo.id
            )
            const isAvailable = variantInfo
              ? variantInfo.available
              : variant.availableForSale

            const gatsbyImage = variant.image
              ? variant.image
              : variant.product.featuredImage

            return (
              <button
                className={`w-20 md:w-24  ${
                  index === selectedVariantIndex
                    ? "font-semibold text-idc-blue"
                    : "text-idc-body"
                } ${!isAvailable && "cursor-not-allowed"}`}
                role="button"
                tabIndex={0}
                onClick={() => {
                  sendSelectItemGTMEvent(variant.selectedOptions[0].value)
                  if (isAvailable) {
                    handleOptionChange(
                      variant.selectedOptions[0].value,
                      variant
                    )
                    setSelectedVariantIndex(index)
                  }
                }}
                key={variant.displayName}
              >
                <div
                  className={`mb-2 h-20 md:h-24 w-20 md:w-24 rounded-md overflow-hidden flex justify-center items-center ${
                    index === selectedVariantIndex
                      ? "border-2 border-idc-blue"
                      : "border hover:border-idc-blue"
                  } transition duration-300 ease-in-out`}
                >
                  <GatsbyImage
                    className={`${!isAvailable && "opacity-25"}`}
                    objectFit="contain"
                    loading="lazy"
                    alt={variant.displayName}
                    image={gatsbyImage ? gatsbyImage?.gatsbyImageData : ""}
                  />
                </div>
                <p className="text-center text-xs">
                  {variant.selectedOptions[0].value}
                </p>
                {variant.selectedOptions[1] && (
                  <p className="text-center text-xs">
                    + {variant.selectedOptions[1].name} -{" "}
                    {variant.selectedOptions[1].value}{" "}
                  </p>
                )}
              </button>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default VariantButtons
