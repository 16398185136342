import React, { FC } from "react"
import { Link } from "gatsby"

export interface ArrowLinkProps {
  text: string
  url: string
  sizeClass?: string
}

const ArrowLink: FC<ArrowLinkProps> = ({
  text,
  url,
  sizeClass = "text-sm",
}) => {
  return (
    <Link
      to={url}
      className={`${sizeClass} font-bold flex flex-row text-idc-title hover:text-idc-orange`}
    >
      {text}
      <div className="flex flex-col justify-center ml-2">
        <svg
          width={15}
          height={15}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.673 1.698 13.475 7.5l-5.802 5.802M13.185 7.5H1"
            stroke="#EF4223"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Link>
  )
}

export default ArrowLink
