import React, { FC, useEffect, useState, useRef, useLayoutEffect } from "react"
import { Slide } from "react-slideshow-image"
import InnerImageZoom from "react-inner-image-zoom"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import { Helmet } from "react-helmet"

import "react-slideshow-image/dist/styles.css"
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css"
import "./index.css"
import { GatsbyImage } from "gatsby-plugin-image"

export interface ProductImagesProps {
  hasImages: boolean
  hasMultipleImages: boolean
  productVideo: string
  images: {
    id: string
    altText: string
    src: string
    gatsbyImageData: any
  }[]
  activeVariant: {
    image: {
      originalSrc: string
    }
  }
  duration?: number
  autoplay?: boolean
  transitionDuration?: number
  arrows?: boolean
  infinite?: boolean
  imgAttributes?: React.ImgHTMLAttributes<HTMLImageElement>
  easing?: string
}

//first, we will preload the helmet data for the page, which is basically the first image in the array, as a preload
const preloadImage = (
  images: {
    id: string
    altText: string
    src: string
    gatsbyImageData: any
  }[]
) => {
  if (images.length > 0) {
    const { src } = images[0]
    return (
      <Helmet>
        {/* <link rel="preload" fetchpriority="high" as="image" type="image/webp" href={src.replace(/https/g, "https://idrinkcoffee.com/cdn-cgi/image/fit=pad,width=640,height=640,format=webp,metadata=none/https")} /> */}
        {/* instead, we will serve directly from shopify with the same size by replacing the image extension (with .jpg.webp or .jpg.png and so on, we will also append the iamge with query params for the size and width) we won't use cloudflare at all
     first, identify the extension, then replace with .extension.webp, so for png it would be replaced with .png.webp and so on */}
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          type="image/webp"
          href={src.replace(
            /(jpg|png|jpeg|webp|gif)/g,
            "$1?width=640&height=640&format=webp&pad_color=ffffff"
          )}
        />
      </Helmet>
    )
  }
}

const ProductImages: FC<ProductImagesProps> = ({
  images,
  activeVariant,
  productVideo,
}) => {
  const imageData = images.map((image) => {
    const { src, altText, id, gatsbyImageData } = image
    const imageData = {
      src: src,
      alt: altText,
      id: id,
      data: gatsbyImageData,
    }
    return imageData
  })

  const slider = useRef(null)

  const indicators = (imageIndex: number) => {
    //first the video, then the images
    let src62 = imageData[imageIndex].src.replace(
      /(jpg|png|jpeg|webp|gif)/g,
      "$1?width=62&height=62"
    )

    return (
      <div className="flex overflow-hidden justify-center items-center w-16 h-16 border cursor-pointer indicator">
        <img
          src={src62}
          alt={
            images[imageIndex].altText
              ? images[imageIndex].altText
              : `Product Image #${imageIndex + 1}`
          }
          height="62"
          width={Math.round(
            (images[imageIndex].gatsbyImageData.width /
              images[imageIndex].gatsbyImageData.height) *
              62
          )}
          loading="lazy"
        />
      </div>
    )
  }

  useEffect(() => {
    const activeVariantIndex = imageData.findIndex(
      (image) => image?.src && image?.src === activeVariant?.image?.originalSrc
    )
    if (slider?.current && activeVariantIndex > -1) {
      slider.current.goTo(activeVariantIndex)
    }
  }, [activeVariant, imageData])
  let video = productVideo ? (
    <video
      controls={true}
      loop={false}
      muted={false}
      autoPlay={false}
      width="50%"
      height="50%"
      src={process.env.GATSBY_STRAPI_API_URL + productVideo}
      className="w-[300] h-[300] rounded-lg"
    >
      <source
        type="video/mp4"
        src={process.env.GATSBY_STRAPI_API_URL + productVideo}
      />
    </video>
  ) : null
  return (
    // <div className={`mb-16 slide-container aspect-square lg:mb-24`} id="photos" >
    //make sure alignment is in the center or bottom
    <>
      {/* {preloadImage(images)} */}
      <div
        className={`mb-16 slide-container aspect-square lg:mb-24 self-baseline`}
        id="photos"
      >
        {/* temp override */}
        {/* {true || loaded ? ( */}
        <Slide
          ref={slider}
          duration={5000}
          autoplay={false}
          transitionDuration={500}
          arrows={false}
          infinite={true}
          easing="ease"
          indicators={indicators}
        >
          {imageData.map((image, index) => (
            <div
              key={index}
              className="each-slide w-full overflow-hidden flex justify-center items-center aspect-full h-[660] self-top"
            >
              <InnerImageZoom
                className="min-h-[357px]"
                src={image.src.replace(
                  /(jpg|png|jpeg|webp|gif)/g,
                  "$1?width=640&height=640&format=webp&pad_color=ffffff"
                )}
                height={640}
                width={640}
                fullscreenOnMobile={true}
                zoomSrc={image.src.replace(
                  /(jpg|png|jpeg|webp|gif)/g,
                  "$1?width=1920&height=1920"
                )}
                zoomPreload={false}
                hasSpacer={true}
                imgAttributes={{
                  // don't lazy load the first image
                  loading: index === 0 ? "auto" : "lazy",
                  alt: image.altText
                    ? image.altText
                    : activeVariant?.displayName +
                      ` Product Image #${index + 1}`,
                }}
                zoomType="hover"
              />
              {/* <Zoom> */}
              {/* <GatsbyImage
                    className="h-full"
                    image={image.data}
                    alt={image.alt}
                    objectFit="contain"
                    loading={index === 0 ? "eager" : "lazy"}
                  /> */}
              {/* </Zoom> */}
            </div>
          ))}
        </Slide>
        {/* ) : ( */}
        {/* <div className="flex justify-center items-center aspect-square bg-idc-light">
            <svg
              className="fill-idc-subtle"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="0.75s"
                  values="0 12 12;360 12 12"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div> */}
        {/* )} */}
      </div>
      <div className="flex overflow-hidden justify-center items-center w-full h-full">
        {video}
      </div>
    </>
  )
}

export default ProductImages
