import React, { useState, useRef } from "react"
import { subscribeToBackInStock } from "@frontend-sdk/klaviyo"

const Bis = ({ siteId, productId, variantId }) => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const refForm = useRef(null)
  const refButton = useRef(null)

  const onSubmit = (event) => {
    if (loading) return
    setLoading(true)
    event.preventDefault()
    try {
      subscribeToBackInStock({
        siteId,
        email,
        product: productId,
        variant: variantId,
        platform: "shopify",
      })
      setSuccess(true)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }
  const onClose = () => {
    setSuccess(false)
    setError(false)
    refForm.current.style.display = "none"
    refButton.current.style.display = "block"
  }
  return (
    <>
      <button
        className="px-4 py-2 w-60 font-bold text-white rounded-xl bg-idc-orange hover:bg-idc-light-green"
        ref={refButton}
        onClick={() => {
          refForm.current.style.display = "block"
          refButton.current.style.display = "none"
        }}
      >
        Notify me when available
      </button>
      <div ref={refForm} style={{ display: "none" }}>
        <form onSubmit={onSubmit} className="flex flex-col">
          <input
            className="box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05); border-2 border-idc-light-blue: focus:border-blue-500 focus:border-idc-blue"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="px-4 py-2 font-bold text-white rounded-xl bg-idc-orange hover:bg-idc-light-green"
          >
            Submit
          </button>
        </form>
        {success && (
          <div
            onClick={onClose}
            className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
          >
            Subscription Successful. We will email you as soon as this item is
            back in stock.
          </div>
        )}
        {error && (
          <button
            onClick={onClose}
            className="px-4 py-2 font-bold text-white bg-orange-500 rounded hover:bg-orange-700"
          >
            Error
          </button>
        )}
      </div>
    </>
  )
}

export default Bis
