import React, { FC, Fragment } from "react"
import { StoreContext } from "context/store-context"
import { Link } from "gatsby"

import { XIcon } from "@heroicons/react/solid"
import { Transition, Dialog } from "@headlessui/react"
import CartModalLineItem from "../CartModalLineItem"

export interface CartModalProps {
  isVisible: boolean
  setIsVisible: any
}

const CartModal: FC<CartModalProps> = ({ isVisible, setIsVisible }) => {
  const { checkout, loading } = React.useContext(StoreContext)

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        static
        className="flex justify-end absolute inset-0 overflow-hidden z-[9999]"
        open={isVisible}
        onClose={() => setIsVisible(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="flex fixed inset-y-0 right-0 max-w-full left:0 sm:left-auto">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="-translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="-translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="h-screen relative w-full sm:min-w-[32rem] sm:max-w-lg ml-4 px-8 py-16 overflow-x-hidden overflow-y-scroll shadow-xl bg-white rounded-md">
              <div className="flex justify-between items-center pb-4">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold leading-6 text-idc-title"
                >
                  Added to cart
                </Dialog.Title>
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-600 focus:outline-none focus:text-gray-600"
                  onClick={() => setIsVisible(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-4 h-4" />
                </button>
              </div>
              <div className="py-2">
                {checkout.lineItems.map((item, index) => (
                  <CartModalLineItem key={index} item={item} />
                ))}
              </div>
              <div className="flex flex-col md:flex-row">
                <button
                  type="button"
                  className="w-full py-3 mb-2 md:mb-0 md:mr-4 font-bold text-[#253740] border border-[#253740] hover:border-[#F47761] active:border-idc-orange hover:text-[#F47761] active:text-idc-orange bg-white rounded-full transiton duration-300 ease-in-out"
                  onClick={() => setIsVisible(false)}
                >
                  Keep shopping
                </button>
                <Link
                  to="/cart"
                  className="flex justify-center py-3 w-full font-bold text-white rounded-full duration-300 ease-in-out view-cart-btn bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue transiton"
                >
                  View cart
                </Link>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CartModal
