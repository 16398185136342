import React from "react"
import { useQuery } from "urql"
import ProductListing from "components/layouts/ProductListing"

const addonQuery = `
  query products ($query: String!) {
    products (first:12, query: $query) {
      edges {
        node {
          id
          title
          vendor
          productType
          handle
          totalInventory
          tags
          priceRangeV2: priceRange {
              minVariantPrice {
                  currencyCode
                  amount
              }
              maxVariantPrice {
                  currencyCode
                  amount
              }
          }
          productImages: images (first:1) {
              edges {
                  node {
                      altText
                      originalSrc
                      width
                      height
                  }
              }
          }
          variants (first:4) {
              edges {
                  node {
                      sku
                      availableForSale
                      compareAtPriceV2 {
                          currencyCode
                          amount
                      }
                      priceV2 {
                          currencyCode
                          amount
                      }
                  }
              }
          }
        }
      }
    }
  }
`

const Addons = ({ sku = "" }) => {
  const [addonQueryResult] = useQuery({
    query: addonQuery,
    variables: {
      query: `tag:${sku}`,
    },
  })
  const { data } = addonQueryResult

  let productList = []

  const parseProductId = (id) => {
    let productId = ""
    let buff
    if (id?.id) {
      if (typeof window !== "undefined") {
        buff = atob(id)
      } else {
        buff = Buffer.from(id, "base64").toString()
      }
      productId = buff.replace("gid://shopify/Product/", "")
    }

    return productId
  }

  if (data?.products) {
    productList = data?.products?.edges?.map(({ node: product }) => {
      const productImage =
        product.productImages.edges.length > 0
          ? product.productImages.edges[0].node
          : null

      const productData = {
        ...product,
        legacyResourceId: parseProductId(product.id),
        price: product?.priceRangeV2.minVariantPrice?.amount,
        vim: product.tags.includes("VIM"),
        image: productImage?.originalSrc,
        images: [null],
        variants: product.variants.edges.map(({ node: variant }) => ({
          ...variant,
          compareAtPrice: variant?.compareAtPriceV2?.amount,
          price: variant?.priceV2?.amount,
        })),
        inventoryAvailable: product.availableForSale,
      }

      return productData
    })
  }
  return (
    <>
      {productList.length > 0 ? (
        <ProductListing
          products={productList}
          scrollable={true}
          isCart={false}
          listingDetails={{ title: "Recommended Addons", linkBottom: false }}
        />
      ) : null}
    </>
  )
}
export default Addons
