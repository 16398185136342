import React, { useState, useEffect, FC } from "react"
import { formatPrice } from "utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { StoreContext } from "context/store-context"

const Spinner = () => (
  <span className="inline-block">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
        fill="#000"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="0.75s"
          values="0 12 12;360 12 12"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </span>
)
export interface CartModalLineItemProps {
  item: any
  vertical: boolean
}

const CartModalLineItem: FC<CartModalLineItemProps> = ({ item, vertical }) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const [removing, setRemoving] = useState(false)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const { removeLineItem, checkout } = React.useContext(StoreContext)

  const handleRemove = () => {
    setRemoving(true)
    removeLineItem(checkout.id, item, setRemoving)
  }

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  let variantCompareAtPrice = null
  if (item.variant?.compareAtPriceV2?.amount) {
    variantCompareAtPrice =
      Number(item.variant.compareAtPriceV2.amount) * quantity
  }
  const compareAtPrice = formatPrice(
    item.variant?.compareAtPriceV2?.currencyCode,
    variantCompareAtPrice
  )

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        aspectRatio: 1 / 1,
      }),
    [variantImage.src]
  )

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  return (
    <div
      className={`flex ${vertical ? "flex-col" : "flex-row"} items-start py-4`}
    >
      {image && (
        <GatsbyImage
          className="w-32 md:w-44 h-auto md:h-44 shrink-0 mx-0"
          key={variantImage.src}
          image={image}
          objectFit="scale-down"
          alt={variantImage.altText ?? item.variant.title}
        />
      )}

      <div className="flex flex-col text-left ${vertical ? '' : 'ml-4 md:ml-0'}">
        <p className="mb-2 text-idc-subtle text-sm leading-tight">
          {item.variant.sku}
        </p>
        <h2 className="mb-2 text-lg leading-6 font-bold">
          {item.title}
          {item.variant.title === "Default Title"
            ? ""
            : ` - ${item.variant.title}`}
        </h2>
        <div className="mb-2 text-left text-base leading-4">
          <p>{subtotal}</p>
          {compareAtPrice && (
            <p className="line-through" style={{ color: "#968E8C" }}>
              {compareAtPrice}
            </p>
          )}
        </div>
        {item?.customAttributes &&
          item.customAttributes.map(
            (attribute: {
              key:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
              value:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
            }) =>
              attribute.key !== "Tags" ? (
                <p className="text-sm font-medium text-idc-subtle">
                  {attribute.key !== "FreeGiftMessages" && (
                    <>
                      {attribute.key}: {attribute.value}
                    </>
                  )}
                </p>
              ) : null
          )}
        {item.quantity && (
          <div className="mb-2 text-idc-subtle text-sm font-medium">
            Qty: {item.quantity}
          </div>
        )}
      </div>
      <button
        className="font-medium text-xs hover:text-[#F47761] has-tooltip active:text-idc-orange transition duration-300 ease-in-out"
        onClick={handleRemove}
      >
        <span className="tooltip rounded-full p-1 bg-gray-100 text-idc-border-orange -mt-8">
          Remove
        </span>
        {removing ? (
          <Spinner />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        )}
      </button>
    </div>
  )
}

export default CartModalLineItem
