import React, { FC, useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"

import FeatureWithIcon from "components/elements/Feature/FeatureWithIcon"

export interface FeaturesWithIconLayoutProps {
  variantPrice: number
  totalInventory: number
  tags: string[]
  available: boolean
}

const ShippingMessage = () => (
  <StaticQuery
    query={graphql`
      query SiteSettingsQuery {
        siteSettings: strapiSiteSettings {
          shippingInformation {
            id
            startDate
            endDate
            message
          }
        }
      }
    `}
    render={(data) => {
      // if it is 1 in the afternoon in Toronto, or less, we can say "Ships today if you order in (time remaining to 1 pm), or it would be ships tomorrow if you order in (time remaining to 1 pm, except on Saturdays, becuase we don't ship on Sunday, so we'll have to calculate that too on a Saturday, the remaining time will be up to 1pm on Monday. In case a customShippingMessage is available, we will display that instead. and if enableTimeouts exists return shippingMessage instead. Make sure everything is in Toronto Time.
      const shippingInformation = data.siteSettings?.shippingInformation

      const now = new Date()
      const nowEST = new Date(
        now.toLocaleString("en-US", {
          timeZone: "America/Toronto",
        })
      )

      const [shippingMessage, setShippingMessage] = useState("")
      let customShippingMessage = ""
      let enableTimeouts = true

      if (shippingInformation.length > 0) {
        shippingInformation.forEach((dateRange) => {
          const startDate = new Date(dateRange.startDate)
          const endDate = new Date(dateRange.endDate)

          if (nowEST >= startDate && nowEST <= endDate) {
            enableTimeouts = false
            customShippingMessage = (
              <p className="text-xs text-center text-idc-body">
                {dateRange.message}
              </p>
            )
          }
        })
      }

      const updateShippingMessage = () => {
        const now = new Date()
        const nowEST = new Date(
          now.toLocaleString("en-US", {
            timeZone: "America/Toronto",
          })
        )
        const day = nowEST.getDay()
        const hour = nowEST.getHours()
        let minute = nowEST.getMinutes()

        let shippingDay = "Today"
        let hoursLeft = 0

        if (day === 5 && hour >= 13) {
          // If it's Friday after 1pm EST
          shippingDay = "Monday"
          hoursLeft = 72 - hour + 12
        } else if (day === 6) {
          // If it's Saturday
          shippingDay = "Monday"
           hoursLeft = 24 - hour + 24 + 13 // Remaining hours of Saturday + 24 hours of Sunday + 1pm on Monday
        } else if (hour >= 13) {
          // If it's past 1pm EST
          shippingDay = "Tomorrow"
          hoursLeft = 24 - hour + 12
        } else {
          hoursLeft = 12 - hour
        }

        let minutesLeft = 60 - minute
        if (minutesLeft === 60) {
          hoursLeft += 1
          minutesLeft = 0
        }

        const message = (
          <p className="text-xs text-center text-idc-body">
            Ships <span className="font-bold">{shippingDay}</span> if you order
            in the next{" "}
            <span className="font-bold">{`${hoursLeft} hours and ${minutesLeft} minutes`}</span>
          </p>
        )

        setShippingMessage(message)
      }

      useEffect(() => {
        let isSubscribed = true

        if (isSubscribed && enableTimeouts && shippingMessage === "") {
          updateShippingMessage()
        } else if (isSubscribed && enableTimeouts) {
          setTimeout(() => {
            updateShippingMessage()
          }, 60000)
        }

        return () => {
          isSubscribed = false
        }
      }, [shippingMessage])

      if (enableTimeouts) return shippingMessage

      return customShippingMessage
    }}
  />
)

const FeaturesWithIconLayout: FC<FeaturesWithIconLayoutProps> = ({
  variantPrice,
  totalInventory,
  tags,
  available,
}) => {
  const openbox = tags?.filter((tag) => {
    if (tag.includes("openbox")) {
      return tag
    }
  })

  const shippingnis = tags?.filter((tag) => {
    if (tag.includes("shipping-nis")) {
      return tag
    }
  })

  const shippingnisText = shippingnis
    .toString()
    .replace("shipping-nis-", "")
    .replace(/-/g, " ")
  const openboxText =
    "Please Allow up to 5 Business Days for Shipping of Open Box Items"

  return (
    <div className="grid grid-cols-3 gap-5">
      <FeatureWithIcon
        icon="free-shipping"
        iconBgColor="bg-idc-light-blue"
        name={`${variantPrice < 49 ? "Fast Shipping" : "Free Shipping"}`}
        description=""
      >
        {available && shippingnisText ? (
          <p className="text-xs text-idc-body">
            Estimated shipping date is {shippingnisText}
          </p>
        ) : //or if openbox is true
        openbox.length > 0 ? (
          <p className="text-xs text-idc-body">{openboxText}</p>
        ) : available && totalInventory > 0 ? (
          <ShippingMessage /> //shipping message
        ) : null}
      </FeatureWithIcon>
      {!(
        tags.includes("WAR-ACC") ||
        tags.includes("WAR-PAR") ||
        tags.includes("WAR-CON") ||
        tags.includes("WAR-COM") ||
        tags.includes("WAR-FIN") ||
        tags.includes("WAR-GLA") ||
        tags.includes("WAR-GC")
      ) && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Quality First"
          description="Covered by our satisfaction guarantee"
        />
      )}

      {tags.includes("WAR-ACC") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Accessories"
          description="This item is an accessory and is subject to our return policy with a restocking fee"
        />
      )}

      {tags.includes("WAR-PAR") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Parts"
          description="This product is in the Parts category and is not subject to our return policy."
        />
      )}

      {tags.includes("WAR-CON") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-green"
          name="Consumables"
          description="This item is classified as a consumable and is not subject to our return policy."
        />
      )}

      {tags.includes("WAR-COM") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Commercial"
          description="This is a commercial product and not subject to our return policy ."
        />
      )}

      {tags.includes("WAR-GC") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Commercial"
          description="Gift Cards are not refundable ."
        />
      )}

      {tags.includes("WAR-GLA") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Glassware"
          description="This product is in the Glassware & Pitchers category and is not subject to our return policy."
        />
      )}

      {tags.includes("WAR-FIN") && (
        <FeatureWithIcon
          icon="quality-first"
          iconBgColor="bg-idc-light-yellow"
          name="Final Sale"
          description="This is a final sale item and not subject to returns."
        />
      )}

      {tags.includes("VIM") && (
        <FeatureWithIcon
          icon="vim-program"
          iconBgColor="bg-idc-light-green"
          name="VIM Program"
          description="Free extended warranty benefits"
        />
      )}
    </div>
  )
}

export default FeaturesWithIconLayout
