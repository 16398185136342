import React, { FC } from "react"
import { AddToCart } from "components/elements/AddToCart/add-to-cart"
import { Link } from "gatsby"
import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/solid"
import Bis from "components/backInStock"

export interface ProductButtonsProps {
  productId: string
  productUrl: string
  variantLegacyId: string
  name: string
  variantId: string
  quantity: number
  available: boolean
  variantPrice: number
  totalInventory: number
  productType?: string
  storefrontId: string
  vendorName?: string
  leasePrice?: string
  sku?: string
  rentPrice?: string
  tags: string[]
  handle: string
  customAttributes: object
}

const ProductButtons: FC<ProductButtonsProps> = ({
  productId,
  productUrl,
  variantLegacyId,
  sku,
  name,
  variantId,
  quantity,
  available,
  variantPrice,
  productType = "",
  vendorName = "",
  rentPrice = null,
  leasePrice = null,
  tags,
  customAttributes,
}) => {
  const preorder = tags?.filter((tag) => {
    if (tag.includes("preorder")) {
      return tag
    }
  })

  const shippingnis = tags?.filter((tag) => {
    if (tag.includes("shipping-nis")) {
      return tag
    }
  })

  const preorderText = preorder
    .toString()
    .replace("preorder-", "")
    .replace(/-/g, " ")
  const shippingnisText = shippingnis
    .toString()
    .replace("shipping-nis-", "")
    .replace(/-/g, " ")

  return (
    <div className="mb-6">
      {!available && (
        <>
          <div className="margin-top-2">
            {" "}
            <span className="padding-top-100">
              <Bis
                siteId="tuA5ie"
                variantId={variantLegacyId}
                productId={productId}
              />
            </span>{" "}
          </div>
          <br />
        </>
      )}
      <div className="flex gap-2">
        <AddToCart
          tags={tags}
          variantId={variantId}
          quantity={quantity}
          available={available}
          customAttributes={customAttributes}
          title={name}
          price={variantPrice}
          vendorName={vendorName}
          productDetails={{
            productType,
            productId,
            productUrl,
            variantLegacyId,
            sku,
          }}
        />
      </div>

      {preorder.length && available ? (
        <p className="mt-4 text-sm text-center text-idc-subtle">
          Available for Pre-Order. Estimated shipping date is in{" "}
          {shippingnisText ? shippingnisText : preorderText}.
        </p>
      ) : (
        ""
      )}

      {tags && tags.includes("commercial") && !tags.includes("consumer") ? (
        <div className="mx-auto mt-4 w-full rounded-md bg-idc-light">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between items-center w-full pl-6 pr-5 py-[0.375rem] text-[0.9375rem] leading-[2.25rem] font-medium text-left text-idc-body-title rounded focus:outline-none group">
                  <span>Rent or Lease Options</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "" : " rotate-180"
                    } w-5 h-5 text-[#A1A1AA] group-hover:text-idc-body`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col px-6 pt-2 pb-6 text-2xl font-medium text-idc-title">
                  <p className="mb-2">Rent for {rentPrice}/week</p>
                  <p className="mb-3">Lease for {leasePrice}/month</p>
                  <p className="mb-6 text-sm leading-6 text-idc-body">
                    Commercial financing is available via third party providers
                    for commercial equipment. This is only available to business
                    customers. For personal financing we have partnered with
                    Affirm
                    <Link
                      to="/commercial-financing"
                      className="text-idc-blue underline font-bold hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                    >
                      Learn more about our commercial financing
                    </Link>
                  </p>
                  <Link
                    to="/commercial-contact-form"
                    className="w-full text-center py-[5px] text-white text-sm leading-[2.25rem] font-bold bg-idc-blue rounded-full focus:outline-none mb-2 transition duration-300 ease-in-out hover:bg-[#F47761] active:bg-idc-orange"
                  >
                    Get in Touch
                  </Link>
                  <p className="mt-1 text-xs text-center text-idc-body">
                    or call us at{" "}
                    <a
                      className="underline text-idc-blue hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                      href="tel:1-800-425-5405"
                    >
                      1-800-425-5405
                    </a>
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ) : null}

      <div className="mt-4 md:mt-6"></div>
    </div>
  )
}

export default ProductButtons
