import React, {
  FC,
  useEffect,
  useState,
  useRef,
  memo,
  useLayoutEffect,
} from "react"

import loadable from "@loadable/component"

const VideoPlayer = loadable(() => import("components/elements/VideoPlayer"))
import { VideoPlayerProps } from "components/elements/VideoPlayer"
import { VariantButtonsProps } from "components/elements/VariantButtons"
import { ProductButtonsProps } from "components/elements/ProductButtons"
const Specifications = loadable(
  () => import("components/elements/Specifications")
)
import { SpecificationsProps } from "components/elements/Specifications"
const KeyFeaturesLayout = loadable(
  () => import("components/layouts/KeyFeaturesLayout")
)
import { KeyFeaturesLayoutProps } from "components/layouts/KeyFeaturesLayout"
const Overview = loadable(() => import("components/elements/Overview"))
import { OverviewProps } from "components/elements/Overview"
const FeaturesLayout = loadable(
  () => import("components/layouts/FeaturesLayout")
)
import { FeaturesLayoutProps } from "components/layouts/FeaturesLayout"
const ProductFaqLayout = loadable(
  () => import("components/layouts/ProductFaqLayout")
)
import { ProductFaqLayoutProps } from "components/layouts/ProductFaqLayout"
import { SaleCountdownLayoutProps } from "components/layouts/SaleCountdownLayout"

const QuestionLayout = loadable(
  () => import("components/layouts/QuestionLayout")
)
import { QuestionLayoutProps } from "components/layouts/QuestionLayout"
const ReviewsLayout = loadable(() => import("components/layouts/ReviewsLayout"))
import { ReviewsLayoutProps } from "components/layouts/ReviewsLayout"
const WarrantyAndContents = loadable(
  () => import("components/elements/WarrantyAndContents")
)
import { WarrantyAndContentsProps } from "components/elements/WarrantyAndContents"
import { FeaturePanelGridProps } from "components/layouts/FeaturePanelGrid"
import { ProductListingProps } from "components/layouts/ProductListing"
import { GrindSelectProps } from "components/elements/GrindSelect"
import { QuantitySelectProps } from "components/elements/QuantitySelect"

import { Transition } from "@headlessui/react"
import { v4 as uuid } from "uuid"
import StickyBox from "react-sticky-box"

import ProductImages, {
  ProductImagesProps,
} from "components/elements/ProductImages"
import ProductSummary, {
  ProductSummaryProps,
} from "components/elements/ProductSummary"
import ProductDetailsLayout from "components/layouts/ProductDetailsLayout"
import Breadcrumbs from "components/elements/Breadcrumbs/breadcrumbs"

export interface ProductScreenProps {
  imageData: ProductImagesProps
  suggestedProductsData: ProductListingProps
  videoData: VideoPlayerProps
  productSummary: ProductSummaryProps
  variantData: VariantButtonsProps
  grindData: GrindSelectProps
  quantityData: QuantitySelectProps
  productSubscriptionsData: any
  buttonData: ProductButtonsProps
  specificationData: SpecificationsProps
  keyFeatureData: KeyFeaturesLayoutProps
  overviewData: OverviewProps
  saleCountdownData: SaleCountdownLayoutProps
  featureData: FeaturesLayoutProps
  faqData: ProductFaqLayoutProps
  saleEndDate: string
  questionData: QuestionLayoutProps
  reviewData: ReviewsLayoutProps
  warrantyAndContentsData: WarrantyAndContentsProps
  featurePanelGridData: FeaturePanelGridProps
  recommendedProductsData: any
  comboData: any
}

const ProductScreen: FC<ProductScreenProps> = ({
  imageData,
  videoData,
  productSummary,
  variantData,
  grindData,
  quantityData,
  productSubscriptionsData,
  buttonData,
  specificationData,
  keyFeatureData,
  overviewData,
  featureData,
  faqData,
  questionData,
  reviewData,
  warrantyAndContentsData,
  comboData,
  ...props
}) => {
  const isBrowser = typeof window !== "undefined"
  const [anchors, setAnchors] = useState([])
  const [inView, setInView] = useState()
  const [showLinkBar, setShowLinkBar] = useState(false)
  const linksRef = useRef(null)
  const [breadcrumb, setBreadcrumb] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false)

  const photosRef = useRef(null)
  const videosRef = useRef(null)
  const keyFeaturesRef = useRef(null)
  const overviewRef = useRef(null)
  const featuresRef = useRef(null)
  const faqRef = useRef(null)
  const commonQuestionsRef = useRef(null)
  const customerReviewsRef = useRef(null)
  const whatsInTheBoxRef = useRef(null)
  const warrantyAndReturnsRef = useRef(null)
  const SpecificationsRef = useRef(null)

  const sectionRefs = [
    { section: "Photos", ref: photosRef },
    { section: "Videos", ref: videosRef },
    { section: "Key Features", ref: keyFeaturesRef },
    { section: "Overview", ref: overviewRef },
    { section: "Features", ref: featuresRef },
    { section: "FAQs", ref: faqRef },
    { section: "Whats In The Box", ref: whatsInTheBoxRef },
    { section: "Warranty & Returns", ref: warrantyAndReturnsRef },
    { section: "Specifications", ref: SpecificationsRef },
    { section: "Questions and Answers", ref: commonQuestionsRef },
    { section: "Customer Reviews", ref: customerReviewsRef },
  ]

  const handleScroll = (event, element) => {
    event.preventDefault()
    const target = document.getElementById(
      element.toLowerCase().replace(/ /g, "-")
    )
    // target.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // })
    const { offsetTop } = getDimensions(target)
    window.scrollTo({
      top: offsetTop - 68,
      behavior: "smooth",
    })
  }

  const getDimensions = (element) => {
    const { height } = element.getBoundingClientRect()
    const offsetTop = element.offsetTop
    const offsetBottom = offsetTop + height

    return {
      height,
      offsetTop,
      offsetBottom,
    }
  }

  useEffect(() => {
    if (isBrowser) {
      const tempAnchors = []
      const targetElements = [
        "Photos",
        "Videos",
        "Key Features",
        "Overview",
        "Features",
        "FAQs",
        "Whats In The Box",
        "Warranty and Returns",
        "Resources",
        "Specifications",
        "Questions and Answers",
        "Customer Reviews",
      ]

      const callBackFn = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let target = entry.target.id
            setInView(target.toLowerCase().replace(/ /g, "-"))
          }
        })
      }

      const options = {
        root: document.querySelector("#intersection-area"),
        threshold: 0.3,
      }

      const observer = new IntersectionObserver(callBackFn, options)

      targetElements.forEach((element) => {
        const targetElement = document.getElementById(
          `${element.toLowerCase().replace(/ /g, "-")}`
        )

        if (targetElement) {
          tempAnchors.push(element)
          observer.observe(targetElement)
        }
      })

      setAnchors(tempAnchors)
    }
  }, [])

  useEffect(() => {
    setHasLoaded(true)
    const handleScroll = () => {
      if (isBrowser && window.scrollY > 350) {
        const { height: linkBarHeight } = linksRef.current
          ? getDimensions(linksRef.current)
          : { height: 68 }
        const scrollPosition = window.scrollY + linkBarHeight + 2

        const selected = sectionRefs.find(({ section, ref }) => {
          const element = ref.current

          if (anchors.includes(section) && element) {
            const { offsetBottom, offsetTop } = getDimensions(element)
            return scrollPosition > offsetTop && scrollPosition < offsetBottom
          }
        })

        if (selected && selected.section !== inView) {
          setInView(selected.section.toLowerCase().replace(/ /g, "-"))
        }

        setShowLinkBar(true)
      } else {
        setShowLinkBar(false)
      }
    }

    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useLayoutEffect(() => {
    if (window && window.location) {
      const collection = {
        slug:
          "/products/" +
          productSummary.vendorName.toLowerCase().replace(/\s+/g, "-"),
        name: productSummary.vendorName,
      }
      const currentItem = {
        slug: "",
        name: productSummary.name,
      }

      let breadcrumbArray = []
      breadcrumbArray.push(collection)
      breadcrumbArray.push(currentItem)

      setBreadcrumb(breadcrumbArray)
    }
  }, [])

  useEffect(() => {
    if (typeof window === "undefined" || !window?.klaviyo) return null
    const klaviyo = window.klaviyo
    const images = imageData.images.map((image) => image.src)
    const item = {
      ProductName: productSummary.color
        ? `${productSummary.name} - ${productSummary.color}`
        : `${productSummary.name}`,
      ProductID: productSummary.legacyResourceId,
      SKU: productSummary.sku,
      URL: location.href,
      Brand: productSummary.vendorName,
      Price: productSummary.sale_price || productSummary.price,
      Images: images,
    }
    console.log(item)

    klaviyo.push(["track", "Viewed Product", item])
  }, [])

  return (
    <div className="mt-6 md:mt-8 lg:mt-12 px-4 md:px-6 lg:px-10 mx-auto max-w-[85rem]">
      <Transition
        as={React.Fragment}
        show={showLinkBar}
        enter="transition duration-150"
        enterFrom="opacity-0 -translate-y-8"
        enterTo="opacity-100 translate-y-0"
      >
        <div
          ref={linksRef}
          className={`box-border flex sticky top-0 z-10 -mx-4 bg-white border-b md:-mx-6 lg:-mx-10`}
        >
          <div className="absolute top-0 left-0 w-8 h-full bg-gradient-to-r from-white via-white"></div>
          <div className="absolute top-0 right-0 w-8 h-full bg-gradient-to-l from-white via-white"></div>
          <div className="px-4 md:px-6 lg:px-10 flex mx-auto pt-[1.375rem] overflow-x-auto no-scrollbar -mb-px">
            {anchors.map((anchor) => (
              <div
                key={uuid()}
                className="flex flex-col px-3 gap-y-5 md:px-4 shrink-0 items-between"
              >
                <a
                  href={`#${anchor.toLowerCase().replace(/ /g, "-")}`}
                  onClick={(e) => handleScroll(e, anchor)}
                  className={`${
                    inView === anchor.toLowerCase().replace(/ /g, "-")
                      ? "text-idc-blue font-bold"
                      : "text-idc-body"
                  } text-sm  hover:text-idc-blue capitalize`}
                >
                  {anchor}
                </a>
                <div
                  className={`w-[1.5rem] h-[0.1875rem] mx-auto rounded-tl-lg rounded-tr-lg transition-width duration-150 ${
                    inView === anchor.toLowerCase().replace(/ /g, "-")
                      ? "bg-idc-blue w-[1.5rem]"
                      : "w-0"
                  }`}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </Transition>

      <div className="md:flex">
        <div
          className="md:grid md:grid-cols-[minmax(0px,640px),minmax(0px,488px)] mx-auto gap-12 lg:gap-16 xl:gap-[5.5rem] mb-12 lg:mb-20"
          {...props}
        >
          <div className="" id="Intersection Area">
            <div className="md:hidden">
              <ProductSummary {...productSummary} display="title" />
            </div>
            <div className="flex flex-col md:flex-row md:items-center space-y-1 md:space-y-0 mb-4 min-h-[36px]">
              <Breadcrumbs breadcrumbs={breadcrumb} type={"product"} />
            </div>
            <div ref={photosRef}>
              <ProductImages {...imageData} />
            </div>
            <div></div>
            <div className="md:hidden">
              <ProductDetailsLayout
                {...{
                  productSummary,
                  variantData,
                  grindData,
                  quantityData,
                  productSubscriptionsData,
                  buttonData,
                  comboData,
                }}
              />
            </div>
            <div>
              {/* <ProductList
                products={comboData}
                primeVariantId={variantData.variants[0].}
              /> */}
            </div>
            <div ref={videosRef}>
              {<VideoPlayer className="rounded-xl" {...videoData} />}
            </div>

            <div ref={keyFeaturesRef}>
              {<KeyFeaturesLayout {...keyFeatureData} />}
            </div>
            <div ref={overviewRef}>{<Overview {...overviewData} />}</div>
            <div ref={featuresRef}>{<FeaturesLayout {...featureData} />}</div>
            <div ref={faqRef}>{<ProductFaqLayout {...faqData} />}</div>
            <div ref={warrantyAndReturnsRef}>
              {
                <WarrantyAndContents
                  name={productSummary.name}
                  {...warrantyAndContentsData}
                />
              }
            </div>
            <div ref={SpecificationsRef}>
              {<Specifications {...specificationData} />}
            </div>
            <div ref={commonQuestionsRef}>
              {<QuestionLayout {...questionData} />}
            </div>
            <div ref={customerReviewsRef}>
              {<ReviewsLayout {...reviewData} />}
            </div>
          </div>
          <div id="productDetailsLayout" className="hidden md:block">
            {
              <StickyBox offsetTop={96} offsetBottom={16}>
                <ProductDetailsLayout
                  {...{
                    productSummary,
                    variantData,
                    grindData,
                    quantityData,
                    productSubscriptionsData,
                    buttonData,
                    comboData,
                  }}
                />
              </StickyBox>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ProductScreen)
